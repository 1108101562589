import React from 'react';
import s from './styles.module.css'

interface Props {
    label: string,
    value: string
}

const Item = ({label, value}: Props) => {
    return (
        <div className={s.item}>
            <p>{label}</p>
            <div>
                {value}
            </div>
        </div>
    );
};

export default Item;
