import s from './styles.module.css'

const Preloader = () => {
    return (
        <div className={s.container}>
            <div className={s.circle3}></div>
        </div>
    );
};

export default Preloader;