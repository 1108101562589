import {makeAutoObservable} from "mobx";
import {toast} from "react-toastify";

export interface Specialization {
    id: number;
    name: string;
}

export interface DoctorI {
    id: number;
    first_name: string;
    last_name: string;
    patronymic_name: string;
    full_name: string;
    birthday: string;
    specialization: Specialization;
    checked: boolean
}

export interface Specialization {
    id: number;
    name: string;
}

export interface Patient {
    specialization: Specialization[];
    id: number;
    first_name: string;
    last_name: string;
    patronymic_name: string;
    full_name: string;
    birthday: string;
}

export interface Doctor {
    id: number;
    first_name: string;
    last_name: string;
    patronymic_name: string;
    full_name: string;
    birthday: string;
    specialization: Specialization;
    sex: string;
    phone: string;
    email: string;
    description: string;
    patients: Patient[];
}


export class DoctorsStore {
    allDoctors: DoctorI[] = []
    doctor: Doctor | null = null

    constructor(private DoctorsService) {
        makeAutoObservable(this)
    }

    async getDoctors() {
        try {
            const res = await this.DoctorsService.getDoctors()
            this.setAllDoctors(res.data.doctors)
        } catch (e) {

        }
    }

    async getDoctor(id) {
        try {
            const res = await this.DoctorsService.getDoctor(id)
            this.setDoctor(res.data.doctor)
        } catch (e) {
            console.log(e)
        }
    }

    async editDoctor(payload, id, setType) {
        try {
            const res = await this.DoctorsService.editDoctor(payload, id)
            const newDoctors = await this.DoctorsService.getDoctors()
            this.setAllDoctors(newDoctors.data.doctors)
            toast.success('Сохранено!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            })
            setType('show_doctors')
        } catch (e) {
            console.log(e)
            for (let key of Object.keys(e.response.data.errors)) {
                toast.error(e.response.data.errors[key][0], {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    }


    async addedDoctor(payload, setType) {
        try {
            const res = await this.DoctorsService.addedDoctor(payload)
            const newDoctors = await this.DoctorsService.getDoctors()
            this.setAllDoctors(newDoctors.data.doctors)
            toast.success('Врач добавлен!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            setType('show_doctors')
        } catch (e) {
            for (let key of Object.keys(e.response.data.errors)) {
                toast.error(e.response.data.errors[key][0], {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            }
            console.log(e)
        }
    }

    setAllDoctors(doctors) {
        this.allDoctors = doctors
    }

    setDoctor(doctor) {
        this.doctor = doctor
    }

    clearDoctor() {
        this.doctor = null
    }

}
