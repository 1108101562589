const MAX_WEBSOCKET_FAILS = 7;
const MIN_WEBSOCKET_RETRY_TIME = 3000; // 3 sec

const MAX_WEBSOCKET_RETRY_TIME = 300000; // 5 mins

let Socket: any;

export class WebSocketClient {
  conn?: WebSocket;
  connectionUrl = `ws://127.0.0.1:8080/ws`;
  sequence: number;
  connectFailCount: number;
  eventCallback?: (message: any) => void;
  firstConnectCallback?: () => void;
  reconnectCallback?: () => void;
  errorCallback?: (event: Event) => void;
  closeCallback?: (connectFailCount: number) => void;
  connectingCallback?: () => void;
  stop: boolean;
  platform: string;
  connectionTimeout: any;

  constructor() {
    this.sequence = 1;
    this.connectFailCount = 0;
    this.stop = false;
    this.platform = '';
  }

  initialize(opts?: any) {
    const defaults = {
      forceConnection: true,
      connectionUrl: this.connectionUrl,
      webSocketConnector: WebSocket,
    };

    const {connectionUrl, forceConnection, webSocketConnector, platform, ...additionalOptions} = Object.assign({}, defaults, opts);

    if (platform) {
      this.platform = platform;
    }

    if (forceConnection) {
      this.stop = false;
    }

    return new Promise<void>((resolve, reject) => {
      if (this.conn) {
        resolve();
        return;
      }

      if (connectionUrl == null) {
        console.log('websocket must have connection url'); //eslint-disable-line no-console
        reject(new Error('websocket must have connection url'));
        return;
      }

      if (this.connectFailCount === 0) {
        console.log('websocket connecting to ' + connectionUrl); //eslint-disable-line no-console
      }

      Socket = webSocketConnector;
      if (this.connectingCallback) {
        this.connectingCallback();
      }

      const regex = /^(?:https?|wss?):(?:\/\/)?[^/]*/;
      const captured = (regex).exec(connectionUrl);

      let origin;
      if (captured) {
        origin = captured[0];

        if (platform === 'android') {
          // this is done cause for android having the port 80 or 443 will fail the connection
          // the websocket will append them
          const split = origin.split(':');
          const port = split[2];
          if (port === '80' || port === '443') {
            origin = `${split[0]}:${split[1]}`;
          }
        }
      } else {
        // If we're unable to set the origin header, the websocket won't connect, but the URL is likely malformed anyway
        const errorMessage = 'websocket failed to parse origin from ' + connectionUrl;
        console.warn(errorMessage); // eslint-disable-line no-console
        reject(new Error(errorMessage));
        return;
      }

      this.conn = new Socket(connectionUrl, [], {headers: {origin}, ...(additionalOptions || {})});
      this.connectionUrl = connectionUrl;

      this.conn!.onopen = () => {
        if (this.connectFailCount > 0) {
          console.log('websocket re-established connection'); //eslint-disable-line no-console
          if (this.reconnectCallback) {
            this.reconnectCallback();
          }
        } else if (this.firstConnectCallback) {
          this.firstConnectCallback();
        }

        this.connectFailCount = 0;
        resolve();
      };

      this.conn!.onclose = () => {
        this.conn = undefined;
        this.sequence = 1;

        if (this.connectFailCount === 0) {
          console.log('websocket closed'); //eslint-disable-line no-console
        }

        this.connectFailCount++;

        if (this.closeCallback) {
          this.closeCallback(this.connectFailCount);
        }

        let retryTime = MIN_WEBSOCKET_RETRY_TIME;

        // If we've failed a bunch of connections then start backing off
        if (this.connectFailCount > MAX_WEBSOCKET_FAILS) {
          retryTime = MIN_WEBSOCKET_RETRY_TIME * this.connectFailCount;
          if (retryTime > MAX_WEBSOCKET_RETRY_TIME) {
            retryTime = MAX_WEBSOCKET_RETRY_TIME;
          }
        }

        if (this.connectionTimeout) {
          clearTimeout(this.connectionTimeout);
        }

        this.connectionTimeout = setTimeout(
          () => {
            if (this.stop) {
              clearTimeout(this.connectionTimeout);
              return;
            }
            this.initialize(opts);
          },
          retryTime,
        );
      };

      this.conn!.onerror = (evt) => {
        if (this.connectFailCount <= 1) {
          console.log('websocket error'); //eslint-disable-line no-console
          console.log(evt); //eslint-disable-line no-console
        }

        if (this.errorCallback) {
          this.errorCallback(evt);
        }
      };

      this.conn!.onmessage = (evt) => {
        const msg = JSON.parse(evt.data);
        if (msg.seq_reply) {
          if (msg.error) {
            console.warn(msg); //eslint-disable-line no-console
          }
        } else if (this.eventCallback) {
          this.eventCallback(msg);
        }
      };
    });
  }

  setConnectingCallback(callback: () => void) {
    this.connectingCallback = callback;
  }

  setEventCallback(callback: (message: any) => void) {
    this.eventCallback = callback;
  }

  setFirstConnectCallback(callback: () => void) {
    this.firstConnectCallback = callback;
  }

  setReconnectCallback(callback: () => void) {
    this.reconnectCallback = callback;
  }

  setErrorCallback(callback: (event: Event) => void) {
    this.errorCallback = callback;
  }

  setCloseCallback(callback: (connectFailCount: number) => void) {
    this.closeCallback = callback;
  }

  close(stop = false) {
    this.stop = stop;
    this.connectFailCount = 0;
    this.sequence = 1;
    if (this.conn && this.conn.readyState === Socket.OPEN) {
      this.conn.onclose = () => {}; //eslint-disable-line @typescript-eslint/no-empty-function
      this.conn.close();
      this.conn = undefined;
      console.log('websocket closed'); //eslint-disable-line no-console
    }
  }

  sendMessage(action: string) {
    if (this.conn && this.conn.readyState === Socket.OPEN) {
      this.conn.send(action);
    } else if (!this.conn || this.conn.readyState === Socket.CLOSED) {
      this.conn = undefined;
      this.initialize({platform: this.platform});
    }
  }

  zoom(type: 'minus' | 'plus') {
    const actionType = type === 'minus' ? 'ZM' : 'ZP';
    this.sendMessage(actionType);
  }

  focus(type: 'minus' | 'plus') {
    const actionType = type === 'minus' ? 'FM' : 'FP';
    this.sendMessage(actionType);
  }

  light() {
    this.sendMessage('LI');
  }

  stopCommand() {
    this.sendMessage('ST');
  }
}