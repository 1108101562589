import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import ShowPacient from "./show_pacient";
import {StoreContext} from "../../../store.context";
import InfoPacient from "./info_pacient";

export type FilterType = 'show_pacient' | 'info_pacient'

const Patients = observer(() => {
    const {pacientStore} = useContext(StoreContext)

    const [type, setType] = useState<FilterType>('show_pacient')

    return (
        <div>
            {type === 'show_pacient' && <ShowPacient onClick={async (id) => {
                await pacientStore.getPacient(id)
                setType('info_pacient')
            }}/>}
            {type === 'info_pacient' && <InfoPacient setType={setType}/>}
        </div>
    );
});

export default Patients;
