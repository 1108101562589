import React, {useEffect, useRef, useState} from 'react';
import 'react-html5-camera-photo/build/css/index.css';
import {ReactComponent as IcFocusPlus} from '../../assets/focus-in.svg';
import {ReactComponent as IcFocusMinus} from '../../assets/focus-out.svg';
import {ReactComponent as IcZoomPlus} from '../../assets/zoom-in.svg';
import {ReactComponent as IcZoomMinus} from '../../assets/zoom-out.svg';
import {ReactComponent as IcLight} from '../../assets/light.svg';
import s from './styles.module.css'
import {WebSocketClient} from "../../utils/websocketClient";
import Select from "react-select";

interface Props {
  setOpenCamera: (b: boolean) => void
  setImg: (b: string) => void
}

const CameraPC = ({setOpenCamera, setImg}: Props) => {
  const [videoDevices, setVideoDevices] = useState(null);

  const websocket = new WebSocketClient();

  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);
  const canvasRef = useRef(null);

  const getConstraints = (deviceId) => {
    const videoConstraints = {
      deviceId: {
        exact: deviceId,
      },
      video: {
        width: {
          min: 1280,
          ideal: 1920,
          max: 1920,
        },
        height: {
          min: 720,
          ideal: 1080,
          max: 1080
        },
      }
    };
    return {
      video: videoConstraints,
      audio: false
    };
  }

  const getCameraSelection = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter(device => device.kind === 'videoinput');
    const devicesOptions = videoDevices.map((device, index) => {
      return {
        label: device.label || 'Камера ' + (index + 1),
        value: device.deviceId,
      }
    });

    setVideoDevices(devicesOptions);
    videoDevices.forEach((device, index) => {
      startStream(getConstraints(device.deviceId), index);
    })
  };

  useEffect(() => {
    websocket.initialize();
    getCameraSelection();
  }, [])

  useEffect(() => {
    const click = document.getElementById('outer-circle')
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') setOpenCamera(false)

      if (e.altKey && e.code === 'KeyI') {
        click.click()
      }

    })
  }, [])

  useEffect(() => {
    const cameraBox = document.getElementById('camera')
    cameraBox.addEventListener('click', (e: any) => {
      if (e.target.id === 'white-flash') setOpenCamera(false)
    })
  }, [])


  const handleStream = (stream, camera) => {
    const videoRef = camera === 0 ?videoRef1.current : videoRef2.current;
    if (videoRef) {
      videoRef.srcObject = stream;
    }
  };

  const startStream = async (constraints, camera) => {
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    handleStream(stream, camera);
  };

  const doScreenshot = async (camera) => {
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.getContext('2d').drawImage(camera === 0 ? videoRef1.current : videoRef2.current, 0, 0, canvas.width, canvas.height);
    }
    await setImg(canvas.toDataURL('image/jpeg'));
    setOpenCamera(false);
  };

  return (
    <div className={s.camera} id={'camera'}>
      <canvas className={s.canvas} id="canvas" width="1920" height="1080" ref={canvasRef}></canvas>

      <div className={s.camera1}>
        <video autoPlay className={s.video} ref={videoRef1}></video>
        <button className={s.takePhoto} onClick={() => doScreenshot(0)}></button>
      </div>
      <div className={s.camera2}>
        <video autoPlay className={s.video} ref={videoRef2}></video>
        <button className={s.takePhoto} onClick={() => doScreenshot(1)}></button>
      </div>

      <div className={s.tools}>
        <button className={s.zoomIn} onMouseDown={() => websocket.zoom('plus')}
                onMouseUp={() => websocket.stopCommand()}>
          <IcZoomPlus/>
        </button>
        <button className={s.zoomOut} onMouseDown={() => websocket.zoom('minus')}
                onMouseUp={() => websocket.stopCommand()}>
          <IcZoomMinus/>
        </button>

        <hr/>

        <button className={s.focusIn} onMouseDown={() => websocket.focus('plus')}
                onMouseUp={() => websocket.stopCommand()}>
          <IcFocusPlus/>
        </button>
        <button className={s.focusOut} onMouseDown={() => websocket.focus('minus')}
                onMouseUp={() => websocket.stopCommand()}>
          <IcFocusMinus/>
        </button>

        <hr/>

        <button className={s.light} onClick={() => websocket.light()}>
          <IcLight/>
        </button>
      </div>
    </div>
  );
};

export default CameraPC;
