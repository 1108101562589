import React, {ChangeEvent} from 'react';
import s from './styles.module.css'
import {observer} from "mobx-react-lite";

interface Props {
    checked: boolean
    onChange: (bool: boolean) => void
}

const Checkbox = observer(({checked, onChange}: Props) => {
            return (
                <div className={s.form_group}>
                    <input checked={checked} type={'checkbox'} id="html"
                           onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.checked)}/>
                    <label htmlFor="html"/>
                </div>
            );
        }
    )
;

export default Checkbox;
