import React, {useContext, useState} from 'react';
import {StoreContext} from "../../../../store.context";
import PageTitle from "../../../../common/page_title";
import {FilterType} from "../index";
import s from './styles.module.css'
import {calculateAge} from "../../../../utils/calc_bithday";
import HeaderActionPage from "../../../../components/header_action_page";
import Item from "./item";

interface Props {
    setType: (t: FilterType) => void
}

const InfoPacient = ({setType}: Props) => {
    const {pacientStore} = useContext(StoreContext)

    const [filter, setFilter] = useState<string>('')

    return (
        <div>
            <PageTitle arrow={true} onClick={() => setType('show_pacient')}
                       title={pacientStore.pacient.full_name}/>

            <div className={s.container}>
                <div className={s.left}>
                    <div className={s.left_top}>
                        <p className={s.about}>О пациенте</p>

                        <div className={s.item}>
                            <span className={s.title_top}>ID</span>
                            <p className={s.title_bottom}>{pacientStore.pacient.id}</p>
                        </div>

                        <div className={s.item}>
                            <span className={s.title_top}>ФИО</span>
                            <p className={s.title_bottom}>{pacientStore.pacient.full_name}</p>
                        </div>

                        <div className={s.item}>
                            <span className={s.title_top}>День рождения</span>
                            <p className={s.title_bottom}>{pacientStore.pacient.birthday}</p>
                        </div>

                        <div className={s.item}>
                            <span className={s.title_top}>Пол</span>
                            <p className={s.title_bottom}>{pacientStore.pacient.sex}</p>
                        </div>

                        <div className={s.item}>
                            <span className={s.title_top}>Номер телефона</span>
                            <p className={s.title_bottom}>{pacientStore.pacient.phone}</p>
                        </div>

                        <div className={s.item}>
                            <span className={s.title_top}>Почта</span>
                            <p className={s.title_bottom}>{pacientStore.pacient.email}</p>
                        </div>

                        <div className={s.item}>
                            <span className={s.title_top}>Примечание</span>
                            <p className={s.title_bottom}>{pacientStore.pacient.description || 'Нету'}</p>
                        </div>

                    </div>
                    <div className={s.left_bottom}>
                        <p className={s.about}>Врач</p>
                        <div className={s.doctor_box}>
                            <p className={s.doctor_name}>{pacientStore.pacient.doctors[0].full_name}</p>
                            <p className={s.doctor_pac}>{pacientStore.pacient.doctors[0].specialization.name}</p>
                            <p className={s.doctor_years}>{calculateAge(pacientStore.pacient.doctors[0].birthday)} лет</p>
                            <p className={s.doctor_id}>ID:{pacientStore.pacient.doctors[0].id}</p>
                        </div>
                    </div>
                </div>
                <div className={s.right}>
                    <HeaderActionPage hiddenBtns={true} setType={() => {
                    }} onChange={(e) => setFilter(e)} value={filter}/>

                    <div className={s.images_box}>
                        {pacientStore.pacient.images.filter(item => {
                            if (!filter) return true
                            if (item.localization.toLowerCase().includes(filter.toLowerCase())) {
                                return true
                            }
                        }).map((el) => <Item key={el.id} {...el}/>)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InfoPacient;