import React, {useContext, useEffect, useState} from 'react';
import s from './styles.module.css'
import Item from "./item";
import {StoreContext} from "../../../../store.context";
import {PacientI} from "../../../../stores/doctor/pacient.store";
import {observer} from "mobx-react-lite";
import {PageType} from "../index";
import HeaderActionPage from "../../../../components/header_action_page";
import DekstopHeaderDoctorPage from "../../../../components/dekstop_header_doctor_page";

interface Props {
    showCurrentPacient: (e: PageType) => void
}

const ShowAllPacient = observer(({showCurrentPacient}: Props) => {
    const {pacientDoctorStore} = useContext(StoreContext)

    const [pacients, setPacients] = useState<PacientI[]>([])
    const [filter, setFilter] = useState<string>('')

    const handlerSelectPacient = async (id) => {
        await pacientDoctorStore.getCurrentPacient(id)
        showCurrentPacient('show_image_pacient')
    }

    const handleSelectPacientInfo = async (id) => {
        await pacientDoctorStore.getCurrentPacient(id)
        showCurrentPacient('information_pacient')
    }

    useEffect(() => {
        const getDoctors = async () => {
            try {
                await pacientDoctorStore.getPacients()
                setPacients(pacientDoctorStore.allPacient.map((el) => el))
            } catch (e) {
                console.log(e)
            }
        }
        getDoctors()
    }, [])

    return (
        <div className={s.show_all_pacient}>
            <div className={s.input_box}>
                <input placeholder={'Поиск'} type="text" value={filter} onChange={(e) => setFilter(e.target.value)}/>
            </div>
            <DekstopHeaderDoctorPage title={'Пациенты'} arrow={false}/>
            <div className={s.input_box_desc}>
                <HeaderActionPage setType={() => showCurrentPacient('added_pacient')}  onChange={(e) => setFilter(e)} value={filter}/>
            </div>
            <div>
                {pacients.filter(item => {
                    if (!filter) return true
                    if (item.full_name.toLowerCase().includes(filter.toLowerCase())) {
                        return true
                    }
                }).map((el) => {
                    return <Item key={el.id} showCurrentPacient={showCurrentPacient} handleSelectPacientInfo={() => handleSelectPacientInfo(el.id)}
                                 onClick={() => handlerSelectPacient(el.id)} {...el}/>
                })}

            </div>
        </div>
    );
});

export default ShowAllPacient;
