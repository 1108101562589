import React, {useContext, useEffect, useState} from 'react';
import s from './styles.module.css'
import {observer} from "mobx-react-lite";
import {ImagesI} from "../../../../stores/doctor/images.store";
import {StoreContext} from "../../../../store.context";
import {log} from "util";
import Item from "./item";
import {TypePage} from "../index";
import classNames from "classnames";
import Modal from 'react-modal';
import HeaderActionPage from "../../../../components/header_action_page";
import Button from "../../../../common/button";

interface Props {
    setTypePage: (t: TypePage) => void
}

const style = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        height: '393px',
        maxWidth: '810px',
        width: '95%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        background: '#2F2F2F',
        border: '1px solid #666666',
        borderRadius: '6px'
    },
    overlay: {
        background: 'rgba(47,47,47,0.34)',
        backdropFilter: 'blur(5px)'

    }
}

const ShowAllImages = observer(({setTypePage}: Props) => {
    const {imagesStore} = useContext(StoreContext)
    const [filter, setFilter] = useState<string>('')
    const [images, setImages] = useState<ImagesI[]>(null)
    const [checkedAsync, setCheckedAsync] = useState<boolean>(false)

    // const noPhoto = ''
    const noPhoto = (imagesStore.isLoading) ? (+imagesStore?.currentImage?.neural_network_score?.toFixed(2) < 0.15 ? 'Рак не обнаружен или возможно это не родинка' : '') : ''
    // console.log(+imagesStore?.currentImage?.neural_network_score < 0.15)
    useEffect(() => {
        const getAllImages = async () => {
            const res = await imagesStore.getAllImages()
            setImages([...imagesStore.allImages])
            setCheckedAsync(true)
        }
        getAllImages()

    }, [])

    return (
        <div>

            <Modal
                isOpen={imagesStore.isLoading}
                // onAfterOpen={afterOpenModal}
                onRequestClose={() => imagesStore.setIsLoading(false)}
                style={style}
                // contentLabel="Example Modal"
            >
                <div className={s.modal_box}>
                    <h3 className={s.h3}>Оценка искусственного интелекта</h3>
                    <div className={s.index}>
                        <p className={s.index_1}>Подсчитанный индекс:</p>
                        <p className={s.index_2}>{imagesStore.currentImage?.neural_network_score}</p>
                    </div>

                    <p className={s.noPhoto}>{noPhoto}</p>

                    <div className={s.indicator}>
                        <div className={s.indicator1}/>
                        <div className={s.indicator2}/>
                        <div className={s.indicator3}/>
                    </div>

                    <p className={s.sub_title}>Результаты основанны на статистике. <br/>Диагностика - это
                        ответственность
                        врача</p>
                    <div className={classNames(s.btn, s.btn_confirm)}
                         onClick={() => imagesStore.setIsLoading(false)}>Понятно
                    </div>
                </div>
            </Modal>

            <div className={s.action_box}>
                <HeaderActionPage hiddenBtns={true} setType={() => {
                }} onChange={(e) => setFilter(e)} value={filter}/>
                <Button className={s.gary} onClick={() => {
                }} title={'Удалить'} type={'gray'}/>
            </div>
            <div className={s.input_box}>
                <input placeholder={'Поиск'} type="text" value={filter} onChange={(e) => setFilter(e.target.value)}/>
            </div>
            {((images?.length === 0 || !images) && checkedAsync) &&
                <h1 className={s.empty_images}>У вас пока нет изображений!</h1>}
            {images?.filter(item => {
                if (!filter) return true
                if (item.patient.full_name.toLowerCase().includes(filter.toLowerCase())) {
                    return true
                }
            })?.map((el) => <Item key={el.id} setTypeEdit={async () => {
                await imagesStore.getCurrentImage(el.id)
                setTypePage('edit_images')
            }} onClick={async () => {
                await imagesStore.getCurrentImage(el.id)
                setTypePage('current_images')
            }} {...el}/>)}
        </div>
    );
});

export default ShowAllImages;