import axiosConfig from "../../../api";

export class SpecialService {

    async getAllSpec() {
        const res = await axiosConfig().get('/api/admin/specializations', {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        })

        return res
    }

    async getCurrentSpec(id: number) {
        const res = await axiosConfig().get(`/api/admin/specializations/${id}`, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        })

        return res
    }

    async addedSpec(name: string) {
        const res = await axiosConfig().post(`/api/admin/specializations`, {name}, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        })

        return res
    }

    async editSpec(id: number, name: string) {
        const res = await axiosConfig().put(`/api/admin/specializations/${id}`, {name}, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        })

        return res
    }
}
