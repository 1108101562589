import React from 'react';
import s from './styles.module.css'
import {ReactComponent as Arrow} from '../../../../assets/arow_logout.svg'

interface Props {
    title: string
    title2?: string
}

const Item = ({title, title2}: Props) => {
    return (
        <div className={s.item}>
            <p className={s.title}>{title}</p>
            <p className={s.title2}>{title2}</p>
            <Arrow/>
        </div>
    );
};

export default Item;
