import axiosConfig from "../../../api";
import axios from "axios";

export interface AddedPacientI {
    first_name: string;
    last_name: string;
    patronymic_name?: string;
    sex: string;
    birthday: string;
    phone: string;
    email: string;
    description?: string;
}

export class PacientDoctorService {

    async getPacients() {
        const res = await axiosConfig().get('/api/doctor/patients', {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        })


        return res
    }

    async addedPacient(payload: AddedPacientI) {
        const res = await axiosConfig().post(`/api/doctor/patients`, {...payload}, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        })

        return res
    }

    async editPacient(payload: AddedPacientI, id: number) {
        const res = await axiosConfig().put(`/api/doctor/patients/${id}`, {...payload}, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        })

        return res
    }

    async addedImagePacient(id: number, image: string, localization: string, doctor_comment: string) {
        function DataURIToBlob(dataURI: string) {
            const splitDataURI = dataURI.split(',')
            const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
            const mimeString = splitDataURI[0]?.split(':')[1]?.split(';')[0]

            const ia = new Uint8Array(byteString.length)
            for (let i = 0; i < byteString.length; i++)
                ia[i] = byteString.charCodeAt(i)

            return new Blob([ia], {type: mimeString})
        }

        const file = image ? DataURIToBlob(image) : ''

        const formData = new FormData()
        formData.append('image', file);
        formData.append('localization', localization);
        formData.append('doctor_comment', doctor_comment);

        const res = await axios({
            url: `https://back.photophonesite.ru/api/doctor/patients/${id}/images`,
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            data: formData
        })

        return res
    }

    async getCurrentPacient(id: number) {
        const res = await axiosConfig().get(`/api/doctor/patients/${id}`, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        })
        return res
    }
}
