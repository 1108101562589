import React, {useContext} from 'react';
import s from './styles.module.css'
import Checkbox from "../../../../../common/checkbox";
import {ReactComponent as Edit} from "../../../../../assets/edit_header.svg";
import {TypePage} from "../../index";
import {observer} from "mobx-react-lite";
import {StoreContext} from "../../../../../store.context";

interface Props {
    name: string,
    id: number,
    setType: (e: TypePage) => void
}

const Item = observer(({name, id, setType}: Props) => {
    const {specialStore} = useContext(StoreContext)

    return (
        <div className={s.item}>
            <div className={s.left_side}>
                <Checkbox checked={false} onChange={() => {
                }}/>

                <div className={s.border_box}>
                    <div className={s.info_box} onClick={async () => {
                        await specialStore.getCurrentSpec(id)
                        setType('show_specialization')
                    }}>
                        <p className={s.name}>{name}</p>
                        <p className={s.id}>ID:{id}</p>
                    </div>

                    <div className={s.edit} onClick={async () => {
                        await specialStore.getCurrentSpec(id)
                        setType('edit_specialization')
                    }}>
                        <Edit/>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Item;