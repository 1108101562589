import React, {useContext, useEffect, useState} from 'react';
import s from './styles.module.css'
import Item from "./pacient_item";
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";
import {StoreContext} from "../../../../store.context";
import {PacientI} from "../../../../stores/admin/pacient.store";
import PageTitle from "../../../../common/page_title";
import HeaderActionPage from "../../../../components/header_action_page";
import Button from "../../../../common/button";

interface Props {
    onClick: (id: number) => void
}

interface PacientStateI extends PacientI {
    checked: boolean
}

const ShowPacient = observer(({onClick}: Props) => {
    const naviagte = useNavigate()
    const {doctorsStore, pacientStore} = useContext(StoreContext)

    const [filter, setFilter] = useState<string>('')
    const [pacients, setPacients] = useState<PacientStateI[]>([])

    const handleChangeStatus = (id, checked) => {
        setPacients(pacients.map((el) => el.id === id ? {...el, checked: checked} : el))
    }

    useEffect(() => {
        const getPacient = async () => {
            await pacientStore.getPacients()
            const whatShow = doctorsStore.doctor ? doctorsStore.doctor.patients : pacientStore.allPacient
            setPacients(whatShow.map((el) => ({...el, checked: false})))
        }
        getPacient()
    }, [])

    return (
        <div>
            <PageTitle arrow={Boolean(doctorsStore.doctor)} onClick={() => naviagte('/doctors')}
                       title={'Пациенты'}/>
            <HeaderActionPage hiddenBtns={true} setType={() => {
            }} onChange={(e) => setFilter(e)} value={filter}/>

            <Button onClick={() => {}} className={s.gray} title={'Удалить'} type={'gray'}/>

            <div className={s.pacient}>
                {pacients.filter(item => {
                    if (!filter) return true
                    if (item.full_name.toLowerCase().includes(filter.toLowerCase())) {
                        return true
                    }
                }).map((el) => {
                    return <Item
                        onClick={() => onClick(el.id)}
                        handleChangeStatus={(bool: boolean) => handleChangeStatus(el.id, bool)}
                        key={el.id}
                        checked={el.checked}
                        fullName={el.full_name}
                        age={el.birthday}
                        id={el.id}
                    />
                })}
            </div>
        </div>
    );
});

export default ShowPacient;
