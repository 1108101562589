import React from 'react';
import s from './styles.module.css'
import {ReactComponent as LogoAuth} from "../../assets/logo3.svg";

const LogoTitle = () => {
    return (
        <div className={s.header}>
            <LogoAuth/>
        </div>
    );
};

export default LogoTitle;
