import {makeAutoObservable} from "mobx";
import {ImagesService} from "../services/doctor/images.service";
import {toast} from "react-toastify";

export interface Patient {
    id: number;
    first_name: string;
    last_name: string;
    patronymic_name: string;
    full_name: string;
    birthday: string;
}

export interface ImagesI {
    id: number;
    url: string;
    localization: string;
    patient: Patient;
    created_at: string;
}

export interface CurrentImagesI {
    id: number;
    url: string;
    localization: string;
    created_at: string;
    patient: Patient;
    doctor_comment: string;
    neural_network_score?: any;
    updated_at: string;
}

export class ImagesStore {
    allImages: ImagesI[] = null
    currentImage: CurrentImagesI = null
    isLoading: boolean = false

    constructor(private ImagesService) {
        makeAutoObservable(this)
    }

    async getAllImages() {
        try {
            const res = await this.ImagesService.getAllImages()
            this.setAllImages(res.data.images)
        } catch (e) {
            console.log(e)
        }
    }

    async getCurrentImage(id) {
        try {
            const res = await this.ImagesService.getCurrentImage(id)
            this.setCurrentImages(res.data.image)
        } catch (e) {
            console.log(e)
        }
    }

    async editImages(id: number, image: string, localization: string, doctor_comment: string, setOpenModal: (b: boolean) => void) {
        setOpenModal(true)
        try {
            const res = await this.ImagesService.editImages(id, image, localization, doctor_comment)
            this.setCurrentImages(res.data.image)
            toast.success('Изображение сохранено', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            setOpenModal(false)
            this.setIsLoading(true)
        } catch (e) {
            console.log(e)
            setOpenModal(false)
        }
    }

    async getAnalyz(id, id_user) {
        try {
            await this.ImagesService.getAnalyz(id)
            const res = await this.ImagesService.getCurrentImage(id_user)
            this.setCurrentImages(res.data.image)
        } catch (e) {
            console.log(e)
        }
    }

    setAllImages(images: ImagesI[]) {
        this.allImages = images
    }

    setIsLoading(b: boolean) {
        this.isLoading = b
    }

    setCurrentImages(images: CurrentImagesI) {
        this.currentImage = images
    }
}