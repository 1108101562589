import React from 'react';
import s from './style.module.css'

interface Props {
    onClick: () => void,
    title: string,
    type?: string,
    className?: string
}

const Button = ({onClick, title, className, type}: Props) => {
    return (
        <div onClick={onClick} className={`${s.btn}` + ' ' + `${className}`}
             style={{
                 background: (type === 'red' && '#B66959') || (type === 'gray' && '#666666')
             }}
        >
            {title}
        </div>
    );
};

export default Button;
