import React, {useContext} from 'react';
import HeaderDoctorPage from "../../../components/header_doctor_page";
import s from "./styles.module.css";
import Item from "./item";
import {StoreContext} from "../../../store.context";

const SettingDoctor = () => {
    const {authDoctorStore} = useContext(StoreContext)

    return (
        <div className={s.setting}>
            <HeaderDoctorPage>
                <h1 className={s.title}>Настройки</h1>
            </HeaderDoctorPage>

            <div>
                <div className={s.box_item}>
                    <p className={s.item_title}>Камера</p>
                    <Item title={'Захват изображения'}/>
                    <Item title={'Профили'}/>
                </div>

                <div className={s.box_item}>
                    <p className={s.item_title}>Приложение</p>
                    <Item title={'Пользовательский интерфейс'}/>
                    <Item title={'Почта'}/>
                    <Item title={'Безопасность'}/>
                </div>

                <div className={s.box_item}>
                    <p className={s.item_title}>Магазин кредитов</p>
                    <Item title={'Купить'} title2={'0'}/>
                </div>

                <div className={s.box_item}>
                    <p className={s.item_title}>Информация</p>
                    <Item title={'Установки программ'}/>
                    <Item title={'Новость'}/>
                    <Item title={'Версия приложения'} title2={'1.0.0'}/>
                </div>

                <div className={s.logout} onClick={() => authDoctorStore.logout()}>
                    Выход
                </div>

            </div>
        </div>
    );
};

export default SettingDoctor;
