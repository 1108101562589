import React, {useContext, useEffect, useState} from 'react';
import s from './styles.module.css'
import {ReactComponent as Arrow} from "../../../../../assets/arow_logout.svg";
import {ReactComponent as Edit} from "../../../../../assets/edit_header.svg";
import {StoreContext} from "../../../../../store.context";
import {convertDate} from "../../../../../utils/convert_date";
import {observer} from "mobx-react-lite";
import Preloader from "../../../../../common/preloader";
import Modal from 'react-modal';
import classNames from "classnames";
import {NavLink} from "react-router-dom";

interface Props {
    setShowPage: () => void
}

const ShowImg = observer(({setShowPage}: Props) => {
    const {pacientDoctorStore} = useContext(StoreContext)

    useEffect(() => {
        const getCurrentPacient = async () => {
            const res = await pacientDoctorStore.getCurrentPacient(pacientDoctorStore.currentPacient.id)
        }
        getCurrentPacient()
    }, [])
    return (
        <div>
            <div className={s.btn} onClick={setShowPage}>
                <p>Создать фотографию</p>
                <Arrow/>
            </div>

            <div className={s.box}>
                {pacientDoctorStore.currentPacient.images.map((el) => {
                    return <div key={el.id} className={s.item}>
                        <div className={s.img} style={{
                            backgroundImage: `url(https://back.photophonesite.ru/${el.url})`
                        }}/>

                        <div className={s.item_desc}>
                            <NavLink key={el.id} to={'/images'} state={{
                                id: el.id
                            }}>
                                <div>
                                    <p className={s.date}>{convertDate(el.created_at)}</p>
                                    <p className={s.local}>{el.localization}</p>
                                </div>
                            </NavLink>
                            <NavLink key={el.id} to={'/images'} state={{
                                id_edit: el.id
                            }}>
                            <div>
                                <Edit/>
                            </div>
                            </NavLink>
                        </div>
                    </div>

                })}
            </div>
        </div>
    );
});

export default ShowImg;
