import React, { useContext, useEffect, useState} from 'react';
import s from "./styles.module.css";
import Item from "./item";
import {toJS} from "mobx";
import {TypePage} from "../index";
import {observer} from "mobx-react-lite";
import {DoctorI} from "../../../../stores/admin/doctors.store";
import {StoreContext} from "../../../../store.context";
import PageTitle from "../../../../common/page_title";
import HeaderActionPage from "../../../../components/header_action_page";

interface Props {
    setType: (t: TypePage) => void
}

interface DoctorsI extends DoctorI {
    checked: boolean
}

const ShowDoctors = observer(({setType}: Props) => {
    const {doctorsStore} = useContext(StoreContext)

    const [doctors, setDoctors] = useState<DoctorsI[]>(toJS([]))
    const [filter, setFilter] = useState<string>('')

    const handleChangeStatus = (id, checked) => {
        setDoctors(doctors.map((el) => el.id === id ? {...el, checked: checked} : el))
    }

    useEffect(() => {
        const getDoctors = async () => {
            try {
                await doctorsStore.getDoctors()
                setDoctors(doctorsStore.allDoctors.map((el) => ({...el, checked: false})))
            } catch (e) {
                console.log(e)
            }
        }
        getDoctors()
    }, [])

    return (
        <div>
            <PageTitle arrow={false} title={'Врачи'}/>
            <HeaderActionPage setType={() => setType('added_doctor')} onChange={(e) => setFilter(e)} value={filter}/>
            <div className={s.doctors_box}>
                {doctors.filter(item => {
                    if (!filter) return true
                    if (item.full_name.toLowerCase().includes(filter.toLowerCase())) {
                        return true
                    }
                }).map((el) => <Item
                    checked={el.checked}
                    handleChangeStatus={(bool:boolean) => handleChangeStatus(el.id, bool)}
                    setType={setType}
                    onClick={async () => {
                        await doctorsStore.getDoctor(el.id)
                        setType('edit_doctor')
                    }} key={el.id}
                    fullName={el.full_name} age={el.birthday} id={el.id}
                    specialization={el.specialization}/>)}
            </div>
        </div>
    );
});

export default ShowDoctors;
