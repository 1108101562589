import React, {useContext, useEffect, useState} from 'react';
import s from "./styles.module.css";
import HeaderDoctorPage from "../../../components/header_doctor_page";
import ShowAllImages from "./show_all_images";
import {observer} from "mobx-react-lite";
import CurrentImages from "./current_images";
import {ReactComponent as Arrow} from "../../../assets/arow_logout.svg";
import {ReactComponent as Edit} from "../../../assets/edit_header.svg";
import {useLocation} from "react-router-dom";
import {StoreContext} from "../../../store.context";
import DekstopHeaderDoctorPage from "../../../components/dekstop_header_doctor_page";
import HeaderActionPage from "../../../components/header_action_page";
import Button from "../../../common/button";
import EditImages from "./edit_images";

export type TypePage = "show_all_images" | "current_images" | "edit_images"

const ImagesDoctor = observer(() => {
    const [typePage, setTypePage] = useState<TypePage>('show_all_images')

    const {imagesStore} = useContext(StoreContext)

    const location = useLocation()

    useEffect(() => {
        if (location.state?.id) {
            const getCurrenImage = async () => {
                try {
                    await imagesStore.getCurrentImage(location.state?.id)
                    setTypePage('current_images')
                } catch (e) {
                    console.log(e)
                }
            }
            getCurrenImage()
        } else {
            return
        }

        if (location?.state?.id_edit) {
            const getEditImage = async () => {
                try {
                    await imagesStore.getCurrentImage(location?.state?.id_edit)
                    setTypePage('edit_images')
                } catch (e) {
                    console.log(e)
                }
            }

            getEditImage()

        } else {
            return
        }
    }, [location.state?.id, location.state?.id_edit])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [typePage])

    return (
        <div className={s.images}>
            <HeaderDoctorPage>
                {typePage === 'show_all_images' && <h1 className={s.title}>Изображения</h1>}
                {typePage === 'current_images' &&
                    <div className={s.header_item}>
                        <Arrow onClick={() => setTypePage('show_all_images')} className={s.arrow}/>
                        <h1 className={s.title}>Изображение</h1>
                        <Edit className={s.edit} onClick={() => setTypePage('edit_images')}/>
                    </div>}

                {typePage === 'edit_images' &&
                    <div className={s.header_item}>
                        <Arrow onClick={() => setTypePage('show_all_images')} className={s.arrow}/>
                        <h1 className={s.title}>Редактировать изображение</h1>
                    </div>}
            </HeaderDoctorPage>

            {typePage === 'show_all_images' &&
                <DekstopHeaderDoctorPage title={'Изображения'} arrow={false} onClick={() => {
                }}/>}
            {typePage === 'current_images' &&
                <DekstopHeaderDoctorPage title={imagesStore?.currentImage?.patient?.full_name} arrow={true}
                                         onClick={() => setTypePage('show_all_images')}/>}
            {typePage === 'edit_images' &&
                <DekstopHeaderDoctorPage title={'Редактировать изображение'} arrow={true}
                                         onClick={() => setTypePage('show_all_images')}/>}
            <div>
                {typePage === 'show_all_images' && <ShowAllImages setTypePage={setTypePage}/>}
                {typePage === 'current_images' && <CurrentImages setTypePage={setTypePage}/>}
                {typePage === 'edit_images' && <EditImages setType={setTypePage}/>}
            </div>
        </div>
    );
});

export default ImagesDoctor;
