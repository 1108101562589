import React, {useContext} from 'react';
import s from './styles.module.css'
import {ReactComponent as Edit} from '../../../../../assets/edit_header.svg'
import {calculateAge} from "../../../../../utils/calc_bithday";
import {PacientI} from "../../../../../stores/doctor/pacient.store";
import {observer} from "mobx-react-lite";
import Checkbox from "../../../../../common/checkbox";
import {PageType} from "../../index";
import {StoreContext} from "../../../../../store.context";

interface Props extends PacientI {
    onClick: () => void
    handleSelectPacientInfo: () => void
    showCurrentPacient:(t:PageType) => void
}

const Item = observer(({
                           birthday,
                           first_name,
                           full_name,
                           id,
                           showCurrentPacient,
                           last_name,
                           patronymic_name,
                           onClick,
                           handleSelectPacientInfo
                       }: Props) => {
    const {pacientDoctorStore} = useContext(StoreContext)

    return (
        <div className={s.item}>
            <div className={s.checkbox}>
                <Checkbox checked={false} onChange={() => {
                }}/>
            </div>

            <div className={s.box_checkbox_info}>
                <div style={{
                    cursor:'pointer'
                }} onClick={handleSelectPacientInfo}>

                    <p className={s.full_name}>{full_name}</p>
                    <p className={s.age}>{calculateAge(birthday)} лет</p>
                    <p className={s.id}>ID: {id}</p>
                </div>


                <div style={{
                    cursor:'pointer'
                }}>
                    <Edit onClick={async () => {
                        await pacientDoctorStore.getCurrentPacient(id)
                        showCurrentPacient('edit_pacient')
                    }}/>
                </div>
            </div>
        </div>
    );
});

export default Item;
