import {makeAutoObservable} from 'mobx'
import {toast} from "react-toastify";

export class AuthStore {
    private isLoginUser = false

    constructor(private AuthService) {
        makeAutoObservable(this)
    }

    async login(loginRequest, navigate) {
        try {
            const token = await this.AuthService.login(loginRequest)
            localStorage.setItem('token', token.data.token)
            navigate("/")
            this.setIsAuth(true)
        } catch (e) {
            toast.error('Неверный логин или пароль!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            console.log(e)
            this.setIsAuth(false)
        }
    }

    async logout() {
        try {
            const res = await this.AuthService.logout()
            localStorage.removeItem('token')
            this.setIsAuth(false)
        } catch (e) {
            console.log(e)
        }
    }

    isAuth() {
        if (localStorage.getItem('token')) {
            this.setIsAuth(true)
        } else {
            this.setIsAuth(false)
        }
    }

    private setIsAuth(auth) {
        this.isLoginUser = auth
    }

    isLogin() {
        return this.isLoginUser
    }
}
