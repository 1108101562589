import React, {useContext, useEffect, useState} from 'react';
import PageTitle from "../../../../common/page_title";
import HeaderActionPage from "../../../../components/header_action_page";
import {observer} from "mobx-react-lite";
import {StoreContext} from "../../../../store.context";
import {SpecI} from "../../../../stores/admin/special.store";
import Button from "../../../../common/button";
import s from './styles.module.css'
import Item from "./item";
import {TypePage} from "../index";

interface Props {
    setType: (t: TypePage) => void
}

const AllSpecialization = observer(({setType}: Props) => {
    const {specialStore} = useContext(StoreContext)

    const [filter, setFilter] = useState<string>('')
    const [allSpec, setAllSpec] = useState<SpecI[]>([])

    useEffect(() => {
        const getAllSpec = async () => {
            try {
                await specialStore.getAllSpec()
                setAllSpec(specialStore.specializations)
            } catch (e) {
                console.log(e)
            }
        }
        getAllSpec()
    }, [])
    return (
        <div>
            <PageTitle arrow={false} onClick={() => {
            }}
                       title={"Специализации"}/>
            <HeaderActionPage hiddenBtns={true} setType={() => {
            }} onChange={(e) => setFilter(e)} value={filter}/>
            <div className={s.btns}>
                <Button onClick={() => setType('added_specialization')} title={'Создать'} type={'red'}/>
                <Button className={s.gray_btn} onClick={() => {
                }} title={'Удалить'} type={'gray'}/>
            </div>
            <div>
                {allSpec.filter(item => {
                    if (!filter) return true
                    if (item.name.toLowerCase().includes(filter.toLowerCase())) {
                        return true
                    }
                }).map((el) => <Item key={el.id} setType={(t:TypePage) => setType(t)} {...el}/>)}
            </div>
        </div>
    );
});

export default AllSpecialization;