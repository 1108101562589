import React from 'react';
import s from './styles.module.css'
import {ImagesI} from "../../../../../stores/doctor/images.store";
import {convertDate} from "../../../../../utils/convert_date";
import {ReactComponent as Arrow} from "../../../../../assets/arow_logout.svg";
import {ReactComponent as Edit} from "../../../../../assets/edit_header.svg";
import Checkbox from "../../../../../common/checkbox";

interface Props extends ImagesI {
    onClick: () => void
    setTypeEdit: () => void
}

const Item = ({localization, patient, url, created_at, id, onClick, setTypeEdit}: Props) => {
    return (
        <div className={s.main_item_box}>
            <div className={s.checkbox}>
                <Checkbox checked={false} onChange={() => {
                }}/>
            </div>
            <div className={s.item} onClick={(e) => {
                e.stopPropagation()
                onClick()
            }}>
                <div className={s.img} style={{
                    backgroundImage: `url(https://back.photophonesite.ru/${url})`
                }}/>

                <div className={s.item_desc}>
                    <div style={{
                        cursor:'pointer'
                    }}>
                        <p className={s.date}>{convertDate(created_at)}</p>
                        <p className={s.name}>{patient.full_name}</p>
                        <p className={s.local}>{localization}</p>
                    </div>
                    <div onClick={(e) => {
                        e.stopPropagation()
                        setTypeEdit()
                    }} className={s.edit_box}>
                        <Edit/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Item;
