import React, {useContext, useState} from 'react';
import s from './styles.module.css'
import {observer} from "mobx-react-lite";
import {TypePage} from "../index";
import {StoreContext} from "../../../../store.context";
import PageTitle from "../../../../common/page_title";
import Input from "../../../../common/input";
import Button from "../../../../common/button";

interface Props {
    setType: (t: TypePage) => void
}

const EditSpecialization = observer(({setType}: Props) => {
    const {specialStore} = useContext(StoreContext)

    const [value, setValue] = useState<string>(specialStore?.currentSpecialization?.name)

    return (
        <div>
            <PageTitle arrow={true} onClick={() => setType('all_specialization')}
                       title={specialStore?.currentSpecialization?.name}/>
            <div className={s.edit_box}>
                <div className={s.id_box}>
                    <span>ID Специализации</span>
                    <div className={s.id_box_main}>
                        {specialStore?.currentSpecialization?.id}
                    </div>
                </div>

                <div className={s.name_box}>
                    <Input lable={'Название'} value={value} setValue={(e) => setValue(e.target.value)}/>
                </div>

                <div className={s.btn_box}>
                    <Button onClick={async () => {
                        await specialStore.editSpec(specialStore?.currentSpecialization?.id, value)
                        setType('all_specialization')
                    }} title={'Сохранить'} type={'red'}/>
                    <Button onClick={() => setType('all_specialization')} className={s.gray} title={'Назад'}
                            type={'gary'}/>
                </div>
            </div>


        </div>
    );
});

export default EditSpecialization;