import React, {useContext, useState} from 'react';
import s from './styles.module.css'
import {StoreContext} from "../../../../store.context";
import {observer} from "mobx-react-lite";
import {CurrentPacientI} from "../../../../stores/doctor/pacient.store";
import Item from "./item";
import {convertDate} from "../../../../utils/convert_date";
import {ReactComponent as Arrow} from "../../../../assets/arow_logout.svg";
import Button from "../../../../common/button";
import DekstopHeaderDoctorPage from "../../../../components/dekstop_header_doctor_page";
import {PageType} from "../index";
import HeaderActionPage from "../../../../components/header_action_page";
import DescktopItemImage from "./descktop_item_image";

interface Props {
    setShowPage: (t: PageType) => void
}

const ShowInfoPacient = observer(({setShowPage}: Props) => {
    const {pacientDoctorStore} = useContext(StoreContext)

    const [currentPacient, setCurrentPacient] = useState<CurrentPacientI>(pacientDoctorStore.currentPacient)
    const [filter, setFilter] = useState<string>('')

    return (
        <div>
            <DekstopHeaderDoctorPage onClick={() => setShowPage('all_pacient')}
                                     title={pacientDoctorStore.currentPacient.full_name} arrow={true}/>
            <div className={s.content_box}>
                <div className={s.left}>
                    <p className={s.about}>
                        О пациенте
                    </p>
                    <div className={s.mobile_box}>
                        <Item label={'Имя'} value={currentPacient.first_name}/>
                        <br/>
                        <Item label={'Фамилия'} value={currentPacient.last_name}/>
                        <br/>
                        <Item label={'Отчество'} value={currentPacient.patronymic_name}/>
                        <br/>
                        <Item label={'Дата рождения'} value={convertDate(currentPacient.birthday)}/>
                        <br/>
                        <Item label={'Пол'} value={currentPacient.sex}/>
                        <br/>
                        <Item label={'Номер телефона'} value={currentPacient.phone}/>
                        <br/>
                        <Item label={'Почта'} value={currentPacient.email}/>
                        <br/>
                        <Item label={'Коментарий'} value={currentPacient.description}/>
                        <div className={s.btn} onClick={() => setShowPage('show_image_pacient')}>
                            <p>Изображения пациента</p>
                            <Arrow/>
                        </div>

                        <Button onClick={() => {
                        }} className={s.gray} title={'Удалить пациента'} type={'gray'}/>
                    </div>

                    <div className={s.desk_box}>
                        <Item label={'ID'} value={`ID: ${currentPacient.id}`}/>
                        <br/>
                        <Item label={'ФИО'} value={currentPacient.full_name}/>
                        <br/>
                        <Item label={'Дата рождения'} value={convertDate(currentPacient.birthday)}/>
                        <br/>
                        <Item label={'Пол'} value={currentPacient.sex}/>
                        <br/>
                        <Item label={'Номер телефона'} value={currentPacient.phone}/>
                        <br/>
                        <Item label={'Почта'} value={currentPacient.email}/>
                        <br/>
                        <Item label={'Примечание'} value={currentPacient.description}/>
                        {/*<br/>*/}
                        <Button onClick={() => setShowPage('edit_pacient')} className={s.gray} title={'Редактировать'}
                                type={'gray'}/>
                        <br/>
                        <Button onClick={() => {
                        }} className={s.gray} title={'Удалить пациента'} type={'gray'}/>
                    </div>

                </div>


                <div className={s.right}>
                    <HeaderActionPage hiddenBtns={true} setType={() => {
                    }} onChange={(e) => setFilter(e)} value={filter}/>
                    <div className={s.added_image_btn} onClick={() => setShowPage('create_image')}>
                        Создать фотографию
                    </div>

                    <div className={s.items_box}>
                        {pacientDoctorStore.currentPacient.images.length === 0 && <h2 className={s.empty_images}>У вас пока нет изображений!</h2>}
                        {pacientDoctorStore.currentPacient.images?.filter(item => {
                            if (!filter) return true
                            if (item.localization.toLowerCase().includes(filter.toLowerCase())) {
                                return true
                            }
                        })?.map((el, id) => <DescktopItemImage key={el.id} {...el}/>)}
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ShowInfoPacient;
