import React from 'react';
import s from "../../show_image_pacient/show_img/styles.module.css";
import {convertDate} from "../../../../../utils/convert_date";
import {ReactComponent as Edit} from "../../../../../assets/edit_header.svg";
import {NavLink} from "react-router-dom";
import {Image} from "../../../../../stores/admin/pacient.store";

interface Props extends Image {

}

const DescktopItemImage = ({url, created_at, localization, id}: Props) => {
    return (
        // <NavLink key={el.id} to={'/'} state={{
        //     id: el.id
        // }}>
        <div className={s.item}>
            <div className={s.img} style={{
                backgroundImage: `url(https://back.photophonesite.ru/${url})`
            }}/>

            <div className={s.item_desc}>
                <div>
                    <NavLink to={'/images'} state={{
                        id: id
                    }}>
                        <p className={s.date}>{convertDate(created_at)}</p>
                        <p className={s.local}>{localization}</p>
                        <p className={s.id}>ID: {id}</p>
                    </NavLink>
                </div>
                <NavLink to={'/images'} state={{
                    id_edit: id
                }}>
                    <Edit/>
                </NavLink>
            </div>
        </div>
        // </NavLink>
    );
};

export default DescktopItemImage;
