import React, {useContext, useEffect, useState} from 'react';
import s from './styles.module.css'
import Input from "../../../../../common/input";
import Button from "../../../../../common/button";
import {StoreContext} from "../../../../../store.context";
import {ReactComponent as Close} from "../../../../../assets/close.svg";
import {observer} from "mobx-react-lite";
import Modal from 'react-modal';
import Preloader from "../../../../../common/preloader";
import DekstopHeaderDoctorPage from "../../../../../components/dekstop_header_doctor_page";
import {PageType} from "../../index";
import {ReactComponent as Download} from "../../../../../assets/download_btn.svg";
import {ReactComponent as Camera} from "../../../../../assets/camera.svg";
import CameraPC from "../../../../../components/camera";

interface Props {
    setShowPage: (t: PageType) => void
}

interface ValuesI {
    localization: string,
    doctor_comment: string
}

const style = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        height: '300px',
        maxWidth: '810px',
        width: '95%',
        marginRight: 'calc(-50%)',
        transform: 'translate(-50%, -50%)',
        background: '#2F2F2F',
        border: '1px solid #666666',
        borderRadius: '6px',
    },
    overlay: {
        background: 'rgba(47,47,47,0.34)',
        backdropFilter: 'blur(5px)',
    }
}

const CreateImg = observer(({setShowPage}: Props) => {
    const {pacientDoctorStore} = useContext(StoreContext)

    const [openCamera, setOpenCamera] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [img, setImg] = useState<string | null>(null)

    const [values, setValues] = useState<ValuesI>({
        localization: '',
        doctor_comment: ''
    })

    const handlerSendImg = async () => {
        await pacientDoctorStore.addedImagePacient(pacientDoctorStore.currentPacient.id, img, values.localization, values.doctor_comment, () => setShowPage('all_pacient'), setOpenModal)
    }

    const handleClickInput = (element) => {
        let file = element.target.files && element.target.files[0];
        let reader = new FileReader();
        reader.onloadend = function () {
            setImg(reader.result.toString())
        }
        reader.readAsDataURL(file);
    }

    return (
        <div>
            {openCamera && <CameraPC setOpenCamera={setOpenCamera} setImg={setImg}/>}

            <Modal
                isOpen={openModal}
                onRequestClose={() => setOpenModal(true)}
                style={style}
                // contentLabel="Example Modal"
            >
                <div className={s.modal_box}>
                    <h2 className={s.modal_title}>Идёт анализ...</h2>
                    <Preloader/>

                    <p className={s.modal_sub_title}>Наша система проводит иследование. Это может занять какое-то время,
                        пожалуйста подождите.</p>
                </div>
            </Modal>

            <DekstopHeaderDoctorPage onClick={() => setShowPage('information_pacient')}
                                     title={'Создать изображение'} arrow={true}/>

            {!img && <label htmlFor="file-input">
                <div className={s.btn}>
                    <input id="file-input" type="file" name={'file'} onChange={(e) => handleClickInput(e)}/>
                    Загрузить
                </div>

                <div className={s.added_img_box}>

                    <div className={s.added_box}>
                        <input id="file-input" type="file" name={'file'} onChange={(e) => handleClickInput(e)}/>
                        <Download/>
                        <p>Загрузить фотографию</p>
                    </div>
                    <div className={s.camera_box} onClick={(e) => {
                        e.preventDefault()
                        setOpenCamera(true)
                    }
                    }>
                        <Camera/>
                        <p>Открыть камеру</p>
                    </div>
                </div>
            </label>}

            {img && <div className={s.img_box}>
                {/*<img src={img} alt="" className={s.img}/>*/}
                <div className={s.img} style={{
                    backgroundImage: `url(${img})`,
                    borderRadius: '6px'
                }}/>
                <div className={s.close} onClick={() => setImg(null)}>
                    <Close/>
                </div>
            </div>}
            <br/>

            <br/>
            <div className={s.input_box}>
                <Input lable={'Локализация'} value={values.localization}
                       setValue={(e) => setValues({...values, localization: e.target.value})}/>
                <div className={s.text_area_box}>
                    <p>Комментарий</p>
                    <textarea className={s.text_area} value={values.doctor_comment}
                              onChange={(e) => setValues({...values, doctor_comment: e.target.value})}/>
                </div>
                <br/>

                <div className={s.btn_box}>
                    <Button onClick={handlerSendImg} title={'Сохранить'} type={'red'} className={s.btns}/>
                    <br/>
                    <Button onClick={() => setShowPage('show_image_pacient')} title={'Отменить'} type={'gray'}
                            className={s.btns}/>
                </div>
            </div>
        </div>
    );
});

export default CreateImg;
