import React, {useContext} from 'react';
import s from './styles.module.css'
import LogoTitle from "../../common/logo_title";
import {NavLink} from "react-router-dom";
import {linksAdmin} from "./data.constants";
import {ReactComponent as Arrow} from "../../assets/arow_logout.svg";
import {StoreContext} from "../../store.context";

const SideBar = () => {
    const {authStore} = useContext(StoreContext)

    return (
        <div className={s.side_bar}>
            <div className={s.logo_box}>
                <LogoTitle/>
            </div>

            <div className={s.link_box}>
                {linksAdmin.map((el) => <NavLink key={el.path}
                                                 className={(isActive) => isActive.isActive ? s.active : null}
                                                 to={el.path}>{el.title}</NavLink>)}
            </div>

            <div className={s.user_box}>
                <div className={s.user_content}>
                    <p>Вы зашли как:</p>
                    <h2>Админ</h2>
                    <div className={s.btn} onClick={() => authStore.logout()}>
                        <span>Выйти</span>
                        <Arrow/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SideBar;
