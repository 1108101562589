import React from 'react';
import s from './styles.module.css'
import search from '../../../assets/main_img.png'

const Main = () => {
    return (
        <div className={s.main}>
            <img src={search} alt="search"/>
        </div>
    );
};

export default Main;
