import React from 'react';
import s from './styles.module.css'
import {Patient, Specialization} from "../../../../../stores/admin/doctors.store";
import {calculateAge} from "../../../../../utils/calc_bithday";

const Item = ({id, full_name, birthday}: Patient) => {
    return (
        <div className={s.item}>
            <p className={s.name}>{full_name}</p>
            <p className={s.years}>{calculateAge(birthday)} лет</p>
            <p className={s.id}>ID:{id}</p>
        </div>
    );
};

export default Item;