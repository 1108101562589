import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import ShowDoctors from "./show_doctors";
import AddedDoctor from "./added_doctor";
import {StoreContext} from "../../../store.context";
import DoctorInfo from "./doctor_info";

export type TypePage = 'show_doctors' | 'added_doctor' | 'edit_doctor' | 'show_doctor_info'

const Doctors = observer(() => {
    const {doctorsStore} = useContext(StoreContext)

    const [type, setType] = useState<TypePage>('show_doctors')

    useEffect(() => {

        return () => {
            doctorsStore.clearDoctor()
        }
    }, [])

    return (
        <div>
            {type === 'show_doctors' && <ShowDoctors setType={setType}/>}
            {type === 'added_doctor' && <AddedDoctor type={'added_doctor'} setType={setType}/>}
            {type === 'edit_doctor' && <AddedDoctor type={'edit_doctor'} setType={setType}/>}
            {type === 'show_doctor_info' && <DoctorInfo  setType={setType}/>}
        </div>
    );
});


export default Doctors;
