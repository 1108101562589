import React from 'react';
import s from './styles.module.css'

interface Props {
    children: React.ReactNode
}

const HeaderDoctorPage = ({children}: Props) => {
    return (
        <div className={s.header}>
            {children}
        </div>
    );
};

export default HeaderDoctorPage;
