import React from 'react';
import s from './styles.module.css'
import {Image} from "../../../../../stores/admin/pacient.store";
import {convertDate} from "../../../../../utils/convert_date";

const Item = ({id, url, localization, created_at}: Image) => {
    return (
        <div className={s.item}>
            <div className={s.img} style={{
                backgroundImage: `url(https://back.photophonesite.ru/${url})`
            }}/>
            <div className={s.info_box}>
                <p className={s.date}>{convertDate(created_at)}</p>
                <p className={s.local}>{localization}</p>
                <p className={s.id}>ID:{id}</p>
            </div>
        </div>
    );
};

export default Item;
