import {ReactComponent as Camera} from '../../assets/camera.svg'
import {ReactComponent as Image} from '../../assets/image.svg'
import {ReactComponent as Pacient} from '../../assets/pacient.svg'
import {ReactComponent as Setings} from '../../assets/setings.svg'
import React, {SVGProps} from "react";

interface LinkT {
    path: string,
    title: string
    icon?: React.ReactNode
}

interface LinkTDoc extends LinkT {
    icon: React.ReactNode
}


export const linksAdmin: LinkT[] = [
    {path: '/specialization', title: 'Специализации'},
    {path: '/patients', title: 'Пациенты'},
    {path: '/doctors', title: 'Врачи'},
]

export const linksDoctor: LinkTDoc[] = [
    // {path: '/', title: 'Камера', icon: <Camera/>},
    {path: '/images', title: 'Изображения', icon: <Image/>},
    {path: '/patients', title: 'Пациенты', icon: <Pacient/>},
    {path: '/setting', title: 'Настройки', icon: <Setings/>},
]
