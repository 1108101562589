import React from 'react';
import s from './styles.module.css'
import brain from '../../../assets/brain.png'

const MainDoctor = () => {
    return (
        <div className={s.main}>
            <img src={brain} alt="search"/>
        </div>
    );
};

export default MainDoctor;
