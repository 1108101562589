import React, {useContext} from 'react';
import s from "../show_image_pacient/show_img/styles.module.css";
import classNames from "classnames";
import Modal from 'react-modal';
import {StoreContext} from "../../../../store.context";
import {observer} from "mobx-react-lite";

const style = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        height: '360px',
        maxWidth: '810px',
        width: '95%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        background: '#2F2F2F',
        border: '1px solid #666666',
        borderRadius: '6px'
    },
    overlay: {
        background: 'rgba(47,47,47,0.34)',
        backdropFilter: 'blur(5px)'

    }
}


const ModalPacient = observer(() => {
    const {pacientDoctorStore} = useContext(StoreContext)

    const noPhoto = pacientDoctorStore.currentImage?.neural_network_score.toFixed(2) < 0.15 ? 'Рак не обнаружен или возможно это не родинка' : ''
    return (
        <Modal
            isOpen={pacientDoctorStore.isLoading}
            // onAfterOpen={afterOpenModal}
            onRequestClose={() => pacientDoctorStore.setIsLoading(false)}
            style={style}
            // contentLabel="Example Modal"
        >
            <div className={s.modal_box}>
                <h3 className={s.h3}>Оценка искусственного интелекта</h3>
                <div className={s.index}>
                    <p className={s.index_1}>Подсчитанный индекс:</p>
                    <p className={s.index_2}>{pacientDoctorStore.currentImage?.neural_network_score.toFixed(2)} </p>
                </div>

                <p className={s.noPhoto}>{noPhoto}</p>

                <div className={s.indicator}>
                    <div className={s.indicator1}/>
                    <div className={s.indicator2}/>
                    <div className={s.indicator3}/>
                </div>

                <p className={s.sub_title}>Результаты основанны на статистике. Диагностика - это ответственность
                    врача</p>
                <div className={classNames(s.btn, s.btn_confirm)}
                     onClick={() => pacientDoctorStore.setIsLoading(false)}>Понятно
                </div>
            </div>
        </Modal>
    );
});

export default ModalPacient;