import axios, {AxiosInstance} from 'axios';

export default function axiosConfig(): AxiosInstance {
    const token = window.localStorage.getItem('token');

    const instance = axios.create({
        baseURL: 'https://back.photophonesite.ru',
    });

    instance.interceptors.response.use((response) => response, async (error) => {
        if (error.response.status === 401) {
            if (token) {
                localStorage.removeItem('token')
                localStorage.removeItem('role')
                window.location.reload()
                // @ts-ignore
                window.location = "/login"
                // try {
                //     await axiosConfig().delete('/api/admin/auth/logout', {
                //         headers: {
                //             "Authorization": `Bearer ${localStorage.getItem('token')}`
                //         }
                //     })
                // } catch (e) {
                //     console.log(e)
                // }
            }

        }
        throw error;
    });

    return instance;
}
