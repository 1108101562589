import React from 'react';
import s from './styles.module.css'
import {ReactComponent as Arrow} from "../../assets/arow_logout.svg";

interface Props {
    title: string
    arrow: boolean
    onClick?: () => void
}

const PageTitle = ({title, arrow = false, onClick}: Props) => {
    return (
        <div className={s.title_box} onClick={() => arrow && onClick()} style={{
            cursor: arrow && 'pointer'
        }}>
            {arrow && <Arrow/>}
            <p className={s.title}>{title}</p>
        </div>
    );
};

export default PageTitle;
