import React, {useContext, useState} from 'react';
import s from './styles.module.css'
import {StoreContext} from "../../../../store.context";
import {ReactComponent as Close} from "../../../../assets/close.svg";
import {ReactComponent as Download} from "../../../../assets/download_btn.svg";
import Input from "../../../../common/input";
import Button from "../../../../common/button";
import {TypePage} from "../index";
import {toast} from "react-toastify";
import Preloader from "../../../../common/preloader";
import Modal from 'react-modal';
import CameraPC from "../../../../components/camera";
import {ReactComponent as Camera} from "../../../../assets/camera.svg";

interface Props {
    setType: (t: TypePage) => void
}

const style = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        height: '393px',
        maxWidth: '810px',
        width: '95%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        background: '#2F2F2F',
        border: '1px solid #666666',
        borderRadius: '6px'
    },
    overlay: {
        background: 'rgba(47,47,47,0.34)',
        backdropFilter: 'blur(5px)'

    }
}

const EditImages = ({setType}: Props) => {
    const {imagesStore} = useContext(StoreContext)

    const [openCamera, setOpenCamera] = useState(false)
    const [openModal, setOpenModal] = useState(false)

    const [values, setValues] = useState({
        localization: imagesStore.currentImage.localization,
        doctor_comment: imagesStore.currentImage.doctor_comment,
    })
    const [img, setImg] = useState<string | null>(`https://back.photophonesite.ru/${imagesStore.currentImage?.url}`)

    const handleClickInput = (element) => {
        let file = element.target.files && element.target.files[0];
        let reader = new FileReader();
        reader.onloadend = function () {
            setImg(reader.result.toString())
        }
        reader.readAsDataURL(file);
    }

    const handleSaveChanges = async () => {
        if (!img) {
            toast.error('Выберите фотографию', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        } else {
            await imagesStore.editImages(imagesStore.currentImage.id, img, values.localization, values.doctor_comment, setOpenModal)
            setType('show_all_images')
        }

    }
    return (
        <div className={s.edit_box}>
            {openCamera && <CameraPC setOpenCamera={setOpenCamera} setImg={setImg}/>}
            <Modal
                isOpen={openModal}
                // onAfterOpen={afterOpenModal}
                onRequestClose={() => setOpenModal(true)}
                style={style}
                // contentLabel="Example Modal"
            >
                <div className={s.modal_box}>
                    <h2 className={s.modal_title}>Идёт анализ...</h2>
                    <Preloader/>

                    <p className={s.modal_sub_title}>Наша система проводит иследование. <br/>Это может занять какое-то
                        время,<br/>
                        пожалуйста подождите.</p>
                </div>
            </Modal>
            <div>
                {!img && <label htmlFor="file-input">
                    <div className={s.btn}>
                        <input id="file-input" type="file" name={'file'} onChange={(e) => handleClickInput(e)}/>
                        Загрузить
                    </div>
                    <div className={s.added_img_box}>

                        <div className={s.added_box}>
                            <input id="file-input" type="file" name={'file'} onChange={(e) => handleClickInput(e)}/>
                            <Download/>
                            <p>Загрузить фотографию</p>
                        </div>
                        <div className={s.camera_box} onClick={(e) => {
                            e.preventDefault()
                            setOpenCamera(true)
                        }
                        }>
                            <Camera/>
                            <p>Открыть камеру</p>
                        </div>
                    </div>
                </label>}

                {img && <div className={s.img_box}
                             style={{backgroundImage: `url(${img})`}}>
                    <div className={s.close_box} onClick={() => setImg(null)}>
                        <Close/>
                    </div>
                </div>}

                <div className={s.input_box}>
                    <Input lable={'Локализация'} value={values.localization}
                           setValue={(e) => setValues({...values, localization: e.target.value})}/>

                    <div className={s.text_area}>
                        <p>Комментарий</p>
                        <textarea className={s.text_area} value={values.doctor_comment}
                                  onChange={(e) => setValues({...values, doctor_comment: e.target.value})}/>
                    </div>
                </div>
            </div>
            <div className={s.btn_box}>
                <Button className={s.btn1} onClick={handleSaveChanges} title={'Сохранить'} type={'red'}/>
                <Button className={s.btn2} onClick={() => setType('show_all_images')} title={'Отменить'} type={'gray'}/>
            </div>

        </div>
    );
};

export default EditImages;
