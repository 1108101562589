import axiosConfig from "../../../api";

export class PacientService {
    async getAllPacient() {
        const res = await axiosConfig().get('/api/admin/patients', {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        })

        return res
    }

    async getPacient(id:number) {
        const res = await axiosConfig().get(`/api/admin/patients/${id}`,{
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        })

        return res
    }

}
