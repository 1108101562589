import React from 'react';
import s from './styles.module.css'

interface Props {
    label: string,
    title: string
}

const Item = ({label, title}: Props) => {
    return (
        <div>
            <p className={s.p}>
                {label}
            </p>
            <div className={s.item}>
                {title || 'Нету'}
            </div>
        </div>
    );
};

export default Item;