import React from 'react';
import s from './styles.module.css'
import Button from "../../common/button";

interface Props {
    setType?: () => void
    onChange: (e: string) => void
    value: string
    hiddenBtns?: boolean
}

const HeaderActionPage = ({setType, onChange, value, hiddenBtns = false}: Props) => {
    return (
        <div className={s.container}>
            <input type="text" placeholder={'Поиск'} value={value} onChange={(e) => onChange(e.target.value)}/>

            {!hiddenBtns && <div className={s.btn_box}>
                <Button onClick={setType} type={'red'} title={'Создать'}/>
                <Button onClick={() => {
                }} type={'gray'} title={'Удалить'} className={s.remove}/>
            </div>}
        </div>
    );
};

export default HeaderActionPage;
