import React, {useContext} from 'react';
import s from "./styles.module.css";
import {linksDoctor} from "./data.constants";
import {NavLink, useLocation} from "react-router-dom";
import classNames from "classnames";
import LogoTitle from "../../common/logo_title";
import {ReactComponent as Arrow} from "../../assets/arow_logout.svg";
import {StoreContext} from "../../store.context";

const DoctorSideBar = () => {
    const location = useLocation()

    const {authStore} = useContext(StoreContext)
    return (
        <div className={s.link_box_doctor}>
            <div className={s.logo_box_doctor}>
                <LogoTitle/>
            </div>
            <div className={s.items_box}>
                {linksDoctor.map((el) => <NavLink key={el.path} to={el.path} className={s.link_doctor}>
                    <div className={classNames(s.item, location.pathname === el.path && s.active_link)}>
                        <div className={s.icon}>
                            {el.icon}
                        </div>
                        {el.title}
                    </div>
                </NavLink>)}
            </div>

            <div className={s.btn_doctor} onClick={() => authStore.logout()}>
                <span>Выйти</span>
                <Arrow/>
            </div>
        </div>
    );
};

export default DoctorSideBar;
