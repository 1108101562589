import axiosConfig from "../../../api";
import axios from "axios";


export class ImagesService {
    async getAllImages() {
        const res = axiosConfig().get('/api/doctor/images', {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        })

        return res
    }

    async getCurrentImage(id: number) {
        const res = await axiosConfig().get(`/api/doctor/images/${id}`, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        })
        return res
    }

    async getAnalyz(image_id: string) {
        const res = await axiosConfig().put(`/api/doctor/images/${image_id}/score`, {}, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        })
    }

    async editImages(id: number, image: string, localization: string, doctor_comment: string) {
        function DataURIToBlob(dataURI: string) {
            const splitDataURI = dataURI.split(',')
            const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
            const mimeString = splitDataURI[0]?.split(':')[1]?.split(';')[0]

            const ia = new Uint8Array(byteString.length)
            for (let i = 0; i < byteString.length; i++)
                ia[i] = byteString.charCodeAt(i)

            return new Blob([ia], {type: mimeString})
        }

        const file = image ? DataURIToBlob(image) : ''

        const formData = new FormData()
        // formData.append('image', file);
        if (!image.includes('back.photophonesite.ru')) {
            formData.append('image', file)
        }
        formData.append('localization', localization);
        formData.append('doctor_comment', doctor_comment);

        const res = await axios({
            url: `https://back.photophonesite.ru/api/doctor/images/${id}`,
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            data: formData
        })

        return res
    }

}
