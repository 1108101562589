import React, {useContext, useState} from 'react';
import s from './styles.module.css'
import Input from "../../../../common/input";
import Select from "react-select";
import InputMask from 'react-input-mask';
import {StoreContext} from "../../../../store.context";
import {AddedPacientI} from "../../../../stores/services/doctor/pacient.service";
import {observer} from "mobx-react-lite";
import {PageType} from "../index";
import Button from "../../../../common/button";
import DekstopHeaderDoctorPage from "../../../../components/dekstop_header_doctor_page";

const customStyles = {
    control: () => ({
        backgroundColor: '#2F2F2F',
        display: 'flex',
        color: '#fff',
        border: '1px solid #666666',
        borderRadius: '6px',
        height: '52px'
    }),
    singleValue: () => ({
        color: '#fff',
        position: 'absolute',
        left: '15px'
    })
}

interface Props {
    setShowPage: () => void
    type: PageType
}

const AddedPacient = observer(({setShowPage, type}: Props) => {
    const {pacientDoctorStore} = useContext(StoreContext)

    const isAdded = type === 'added_pacient'
    const isEdit = type === 'edit_pacient'
    const selectedPacient = pacientDoctorStore.currentPacient

    const splitDate = selectedPacient ? selectedPacient?.birthday?.split('.') : []

    const [values, setValues] = useState<AddedPacientI>({
        "first_name": isAdded ? "" : selectedPacient.first_name,
        "last_name": isAdded ? "" : selectedPacient.last_name,
        "patronymic_name": isAdded ? "" : selectedPacient.patronymic_name,
        "sex": isAdded ? "" : selectedPacient.sex,
        "birthday": isAdded ? "" : `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`,
        "phone": isAdded ? "" : selectedPacient.phone,
        "email": isAdded ? "" : selectedPacient.email,
        "description": isAdded ? "" : selectedPacient.description,
    })

    const options = [
        {value: 'Женский', label: 'Женский'},
        {value: 'Мужской', label: 'Мужской'},
    ];

    const handleAddedPacient = async () => {
        const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]

        if (isAdded) {
            await pacientDoctorStore.addedPacient({
                ...values,
                birthday: `${new Date(values.birthday).getDate()}.${new Date(values.birthday).getMonth() + 1}.${new Date(values.birthday).getFullYear()}`,
                phone: values.phone.split('').filter(f => numbers.includes(+f) && f !== ' ').join('')
            }, setShowPage)
        }
        if (isEdit) {
            await pacientDoctorStore.editPacient({
                ...values,
                birthday: `${new Date(values.birthday).getDate()}.${new Date(values.birthday).getMonth() + 1}.${new Date(values.birthday).getFullYear()}`,
                phone: values.phone.split('').filter(f => numbers.includes(+f) && f !== ' ').join('')
            }, selectedPacient.id, setShowPage)
        }

    }

    return (
        <div>
            <DekstopHeaderDoctorPage onClick={setShowPage}
                                     title={type === 'added_pacient' ? 'Создание пациента' : 'Редактирование пациента'}
                                     arrow={true}/>
            <div className={s.content_box}>
                <div className={s.left}>

                    {type !== 'added_pacient' && <div className={s.id_box}>
                        <p>ID Пациента</p>
                        <div>
                            ID: {selectedPacient.id}
                        </div>
                        <br/>
                    </div>}

                    <Input lable={'Имя'} value={values.first_name}
                           setValue={(e) => setValues({...values, first_name: e.target.value})}/>
                    <br/>
                    <Input lable={'Фамилия'} value={values.last_name}
                           setValue={(e) => setValues({...values, last_name: e.target.value})}/>
                    <br/>
                    <Input lable={'Отчество'} value={values.patronymic_name}
                           setValue={(e) => setValues({...values, patronymic_name: e.target.value})}/>
                    <br/>

                    <div className={s.sex}>
                        <p>Пол</p>
                        <Select
                            //@ts-ignore
                            options={options}
                            defaultValue={values.sex}
                            //@ts-ignore
                            styles={customStyles}
                            placeholder={type !== 'added_pacient' ? values.sex : 'Не выбрано'}
                            onChange={(e) => {
                                //@ts-ignore
                                setValues({...values, sex: e.value})
                            }}
                        />
                    </div>
                    <br/>

                    <Input setValue={(e) => {
                        setValues({...values, birthday: e.target.value})

                    }} lable={'Дата рождения'}
                           type={'date'}
                           value={values.birthday}
                    />

                    <br/>
                    <div className={s.number_box}>
                        <p>Телефон</p>
                        <InputMask onChange={(e) => {
                            setValues({...values, phone: e.target.value})
                        }} mask={"9 (999) 999-99-99"}
                                   value={values.phone}/>
                    </div>
                    <br/>
                    <Input lable={'Почта'} value={values.email}
                           setValue={(e) => setValues({...values, email: e.target.value})}/>
                    <br/>
                    <div className={s.mobile_description}>
                        <div className={s.text_area_box}>
                            <p>Примечание</p>
                            <textarea className={s.text_area} value={values.description}
                                      onChange={(e) => setValues({...values, description: e.target.value})}/>
                        </div>
                    </div>
                </div>
                <div className={s.right}>
                    <div className={s.text_area_box}>
                        <p>Примечание</p>
                        <textarea className={s.text_area} value={values.description}
                                  onChange={(e) => setValues({...values, description: e.target.value})}/>
                    </div>
                </div>
            </div>

            <div className={s.btn_box}>
                <div className={s.btn} onClick={handleAddedPacient} style={{
                    cursor: 'pointer'
                }}>
                    {isAdded && 'Создать'}
                    {isEdit && 'Сохранить'}
                </div>
                <Button className={s.gray} onClick={setShowPage} title={'Отменить'} type={'gray'}/>
            </div>
        </div>
    );
});

export default AddedPacient;
