import axiosConfig from "../../../api";

interface ILogin {
    name: string,
    password: string
}

export class AuthService {
    async login(loginRequest: ILogin) {
        const res = await axiosConfig().post('/api/admin/auth/login', {
            ...loginRequest
        },)
        return res
    }

    async logout() {
        const res = await axiosConfig().delete('/api/admin/auth/logout', {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        })
        return res
    }
}
