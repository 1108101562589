import React, {useContext} from 'react';
import s from './styles.module.css'
import PageTitle from "../../../../common/page_title";
import {TypePage} from "../index";
import {StoreContext} from "../../../../store.context";
import {observer} from "mobx-react-lite";

interface Props {
    setType: (t: TypePage) => void
}

const ShowSpecialization = observer(({setType}: Props) => {
    const {specialStore} = useContext(StoreContext)

    return (
        <div>
            <PageTitle arrow={true} onClick={() => setType('all_specialization')}
                       title={specialStore?.currentSpecialization?.name}/>

            <div className={s.item}>
                <p className={s.about}>О специализации</p>

                <div className={s.id_box}>
                    <p className={s.id}>ID</p>
                    <p className={s.id_title}>{specialStore?.currentSpecialization?.id}</p>
                </div>

                <div className={s.name_box}>
                    <p className={s.name}>Название</p>
                    <p className={s.name_title}>{specialStore?.currentSpecialization?.name}</p>
                </div>
            </div>
        </div>
    );
});

export default ShowSpecialization;