import React, {useState} from 'react';
import AllSpecialization from "./all_specialixation";
import {observer} from "mobx-react-lite";
import ShowSpecialization from "./show_specialization";
import AddedSpecialization from "./added_specialization";
import EditSpecialization from "./edit_specialization";

export type TypePage = 'all_specialization' | 'edit_specialization' | 'added_specialization' | 'show_specialization'

const Specialization = observer(() => {
    const [type, setType] = useState<TypePage>('all_specialization')

    return (
        <div>
            {type === 'all_specialization' && <AllSpecialization setType={setType}/>}
            {type === 'show_specialization' && <ShowSpecialization setType={setType}/>}
            {type === 'added_specialization' && <AddedSpecialization setType={setType}/>}
            {type === 'edit_specialization' && <EditSpecialization setType={setType}/>}
        </div>
    );
});

export default Specialization;