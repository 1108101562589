import React, {useContext, useEffect} from 'react';
import Auth from "./pages/auth";
import './App.css'
import {StoreContext} from "./store.context";
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";
import Routers from "./rotuers/routers";
import SideBar from "./components/side_bar";
import 'react-toastify/dist/ReactToastify.css';
import 'react-html5-camera-photo/build/css/index.css';
import {ToastContainer} from "react-toastify";
import DoctorSideBar from "./components/side_bar/doctor_side_bar";
import classNames from "classnames";

function App() {
    const {authStore, authDoctorStore} = useContext(StoreContext)

    const isLogin = authStore.isLogin()
    const isLoginDoctor = authDoctorStore.isLogin()

    const role = localStorage.getItem('role')
    useEffect(() => {
        if (role === 'admin') {
            authStore.isAuth()
        }
        if (role === 'doctor') {
            authDoctorStore.isAuth()
        }
    }, [])

    if (!isLogin && !isLoginDoctor) return <Auth/>

    return (
        <div className={'App'}>
            <div
                className={classNames(
                    (role === 'admin' && 'side_bar') ||
                    (role === 'doctor' && 'side_bar_doctor'))}
            >
                {role === 'admin' && <SideBar/>}
                {role === 'doctor' && <DoctorSideBar/>}

            </div>
            <div className={classNames('content', role === 'doctor' && 'content_doctor')}>
                <Routers/>
            </div>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    );
}

const AppObs = observer(App)

export default AppObs;
