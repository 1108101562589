import {AuthStore} from "./stores/admin/auth.store";
import React from "react";
import {AuthService} from "./stores/services/admin/auth.service";
import {DoctorsStore} from "./stores/admin/doctors.store";
import {DoctorsService} from "./stores/services/admin/doctors.service";
import {SpecialStore} from "./stores/admin/special.store";
import {SpecialService} from "./stores/services/admin/special.service";
import {PacientStore} from "./stores/admin/pacient.store";
import {PacientService} from "./stores/services/admin/pacient.service";
import {AuthDoctorStore} from "./stores/doctor/auth.store";
import {AuthDoctorService} from "./stores/services/doctor/auth.service";
import {PacientDoctorStore} from "./stores/doctor/pacient.store";
import {PacientDoctorService} from "./stores/services/doctor/pacient.service";
import {ImagesStore} from "./stores/doctor/images.store";
import {ImagesService} from "./stores/services/doctor/images.service";

interface IStoreContext {
    authStore: AuthStore,
    authDoctorStore: AuthDoctorStore,
    doctorsStore: DoctorsStore,
    specialStore: SpecialStore,
    pacientStore: PacientStore,
    pacientDoctorStore: PacientDoctorStore,
    imagesStore: ImagesStore
}

//doctor store
const authDoctorService = new AuthDoctorService()
const authDoctorStore = new AuthDoctorStore(authDoctorService)

const imagesService = new ImagesService()
const imagesStore = new ImagesStore(imagesService)

const pacientDoctorService = new PacientDoctorService()
const pacientDoctorStore = new PacientDoctorStore(pacientDoctorService, imagesService)

//admin store
const authServices = new AuthService()
const authStore = new AuthStore(authServices)

const doctorsServices = new DoctorsService()
const doctorsStore = new DoctorsStore(doctorsServices)

const specialService = new SpecialService()
const specialStore = new SpecialStore(specialService)

const pacientService = new PacientService()
const pacientStore = new PacientStore(pacientService)


export const StoreContext = React.createContext<IStoreContext>({
    authStore,
    authDoctorStore,
    doctorsStore,
    specialStore,
    pacientStore,
    pacientDoctorStore,
    imagesStore

})
