import React, {useContext} from 'react';
import s from './styles.module.css'
import {StoreContext} from "../../../../store.context";
import {observer} from "mobx-react-lite";
import Item from "./item";
import {convertDate} from "../../../../utils/convert_date";
import {ReactComponent as Arrow} from "../../../../assets/arow_logout.svg";
import classNames from "classnames";
import {TypePage} from "../index";

interface Props {
    setTypePage: (t: TypePage) => void
}

const CurrentImages = observer(({setTypePage}: Props) => {
    const {imagesStore} = useContext(StoreContext)

    const handleImagesClick = () => {
        imagesStore.getAnalyz(imagesStore.currentImage.patient.id, imagesStore.currentImage.id)
    }
    return (
        <div className={s.current_item_box}>
            <div className={s.box_img}>
                <div className={s.img} style={{
                    backgroundImage: `url(https://back.photophonesite.ru/${imagesStore.currentImage?.url})`
                }}/>
            </div>

            <div className={s.box_info}>
                <h2 className={s.about}>О фотографии</h2>
                <br/>
                <Item label={'Пациент'} title={imagesStore.currentImage?.patient.full_name}/>
                <br/>
                <Item label={'Локализация'} title={imagesStore.currentImage?.localization}/>
                <br/>
                <Item label={'Комментарий:'} title={imagesStore.currentImage?.doctor_comment}/>
                <br/>
                <Item label={'Дата создания:'}
                      title={imagesStore.currentImage && convertDate(imagesStore.currentImage?.created_at)}/>
                <br/>
                {/*<br/>*/}
                <span className={s.title_ii}>Оценка искусственного интелекта</span>
                <div className={s.btn_bot} onClick={handleImagesClick}>
                    {imagesStore.currentImage?.neural_network_score}
                </div>
                <br/>
                <div className={classNames(s.btn_bot, s.btn_doctor)} style={{
                    cursor: 'pointer'
                }}>
                    Записать пациента к врачу
                    <Arrow/>
                </div>
                <br/>
                <br/>
                <div className={classNames(s.btn_bot, s.btn_doctor, s.remove)}
                     style={{
                         cursor: 'pointer'
                     }}
                     onClick={() => setTypePage('edit_images')}>
                    Редактировать
                </div>
                <br/>
                <br/>
                <div className={classNames(s.btn_bot, s.btn_doctor, s.remove)} style={{
                    cursor: 'pointer'
                }}>
                    Удалить фотографию
                </div>
            </div>
        </div>
    );
});

export default CurrentImages;
