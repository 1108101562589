import axiosConfig from "../../../api";

export interface ValuesI {
    first_name: string;
    last_name: string;
    patronymic_name: string;
    sex: string;
    birthday: string;
    phone: string;
    email: string;
    description: string;
    specialization_id: number;
}

export class DoctorsService {
    async getDoctors() {
        const res = await axiosConfig().get('/api/admin/doctors', {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        })


        return res
    }

    async addedDoctor(payload: ValuesI) {
        const res = await axiosConfig().post(`/api/admin/doctors`, {...payload}, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        })

        return res
    }

    async getDoctor(id: number) {
        const res = await axiosConfig().get(`/api/admin/doctors/${id}`, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        })

        return res
    }

    async editDoctor(payload: ValuesI, id: number) {
        const res = await axiosConfig().put(`/api/admin/doctors/${id}`, {...payload}, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        })

        return res
    }
}
