import React, {useContext} from 'react';
import s from './styles.module.css'
import {ReactComponent as Arrow} from '../../../../../assets/arow_logout.svg'
import {ReactComponent as Edit} from '../../../../../assets/edit_header.svg'

import {observer} from "mobx-react-lite";

import {NavLink} from "react-router-dom";
import {StoreContext} from "../../../../../store.context";
import Checkbox from "../../../../../common/checkbox";
import {calculateAge} from "../../../../../utils/calc_bithday";
import {TypePage} from "../../index";

interface SubProps {
    name: string,
    id: number
}

interface Props {
    fullName: string,
    age: string,
    id: number,
    specialization: SubProps
    onClick: () => void
    checked: boolean
    handleChangeStatus: (bool: boolean) => void
    setType: (t: TypePage) => void
}

const Item = observer(({
                           checked,
                           fullName,
                           age,
                           id,
                           specialization,
                           onClick,
                           handleChangeStatus,
                           setType
                       }: Props) => {
    const {doctorsStore} = useContext(StoreContext)

    return (
        <div className={s.container}>
            <div className={s.checkbox}>
                <Checkbox checked={checked} onChange={(bool: boolean) => handleChangeStatus(bool)}/>
            </div>

            <div className={s.content}>
                <div onClick={async () => {
                    await doctorsStore.getDoctor(id)
                    setType('show_doctor_info')
                }} style={{
                    cursor: 'pointer'
                }}>
                    <h2 className={s.fullName}>{fullName}</h2>
                    <p className={s.specialization}>{specialization.name}</p>
                    <p className={s.age}>{`${calculateAge(age)} лет`}</p>
                    <p className={s.id}>{`ID:${id}`}</p>
                </div>

                <div className={s.click_box} onClick={onClick}>
                    <Edit/>
                </div>
            </div>
        </div>
    );
})

export default Item;
