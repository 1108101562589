import React from 'react';
import {Route, Routes} from "react-router-dom";

import {observer} from "mobx-react-lite";
import Main from "../pages/admin_page/main";
import Patients from "../pages/admin_page/patients";
import Doctors from "../pages/admin_page/doctors";
import ImagesDoctor from "../pages/doctor_page/images";
import PacientDoctor from "../pages/doctor_page/pacient";
import SettingDoctor from "../pages/doctor_page/setting";
import Specialization from "../pages/admin_page/specialization";
import MainDoctor from "../pages/doctor_page/main";

const Routers = observer(() => {
    const role = localStorage.getItem('role')

    return (
        <Routes>
            {role === 'admin' && <>
                <Route path='/' element={<Main/>}/>
                <Route path='/specialization' element={<Specialization/>}/>
                <Route path='/patients' element={<Patients/>}/>
                <Route path='/doctors' element={<Doctors/>}/>
            </>}

            {role === 'doctor' && <>
                <Route path='/' element={<MainDoctor/>}/>
                <Route path='/images' element={<ImagesDoctor/>}/>
                <Route path='/patients' element={<PacientDoctor/>}/>
                <Route path='/setting' element={<SettingDoctor/>}/>
            </>}

        </Routes>
    );
});

export default Routers;
