import React, {useEffect, useState} from 'react';
import s from "./styles.module.css";
import HeaderDoctorPage from "../../../components/header_doctor_page";
import ShowAllPacient from "./show_all_pacient";
import {ReactComponent as Plus} from "../../../assets/plus.svg";
import {ReactComponent as Arrow} from "../../../assets/arow_logout.svg";
import {ReactComponent as Edit} from "../../../assets/edit_header.svg";
import AddedPacient from "./added_pacient";
import {observer} from "mobx-react-lite";
import ShowInfoPacient from "./shiw_info_pacient";
import CreateImg from "./show_image_pacient/create_img";
import ShowImg from "./show_image_pacient/show_img";
import ModalPacient from "./modal";

export type PageType =
    "all_pacient"
    | "added_pacient"
    | "create_image"
    | "show_image_pacient"
    | "information_pacient"
    | "edit_pacient"

const PacientDoctor = observer(() => {
    const [showPage, setShowPage] = useState<PageType>('all_pacient')

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [showPage])
    return (
        <div className={s.pacient}>
            <ModalPacient/>
            <HeaderDoctorPage>
                {showPage === 'all_pacient' && <div className={s.all_pacient}>
                    <Plus className={s.plus} onClick={() => setShowPage('added_pacient')}/>
                    <h1 className={s.title}>Пациенты</h1>
                </div>}

                {showPage === 'show_image_pacient' && <div className={s.all_pacient}>
                    <Arrow className={s.arrow} onClick={() => setShowPage('information_pacient')}/>
                    <h1 className={s.title}>Изображения пациента</h1>
                </div>}

                {showPage === 'create_image' && <div className={s.all_pacient}>
                    <Arrow className={s.arrow} onClick={() => setShowPage('show_image_pacient')}/>
                    <h1 className={s.title}>Создать изображение</h1>
                </div>}

                {(showPage === 'added_pacient' || showPage === 'edit_pacient') && <div className={s.all_pacient}>
                    <Arrow onClick={() => setShowPage('all_pacient')} className={s.arrow}/>
                    <h1 className={s.title}>
                        {showPage === 'added_pacient' && 'Создание пациента'}
                        {showPage === 'edit_pacient' && 'Редактирование пациента'}
                    </h1>
                </div>}

                {showPage === 'information_pacient' &&
                    <div className={s.all_pacient}>
                        <Arrow onClick={() => setShowPage('all_pacient')} className={s.arrow}/>
                        <h1 className={s.title}>Пациент</h1>
                        <Edit className={s.edit} onClick={() => setShowPage('edit_pacient')}/>
                    </div>}
            </HeaderDoctorPage>


            <div className={s.content}>
                {showPage === 'all_pacient' &&
                    <ShowAllPacient showCurrentPacient={(e: PageType) => setShowPage(e)}/>}

                {showPage === 'show_image_pacient' && <ShowImg setShowPage={() => setShowPage('create_image')}/>}

                {showPage === 'create_image' && <CreateImg setShowPage={setShowPage}/>}

                {showPage === 'added_pacient' &&
                    <AddedPacient setShowPage={() => setShowPage('all_pacient')} type={'added_pacient'}/>}

                {showPage === 'edit_pacient' &&
                    <AddedPacient setShowPage={() => setShowPage('all_pacient')} type={'edit_pacient'}/>}

                {showPage === 'information_pacient' &&
                    <ShowInfoPacient setShowPage={setShowPage}/>}
            </div>

        </div>
    );
});

export default PacientDoctor;
