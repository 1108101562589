import React, {useContext, useState} from 'react';
import s from './styles.module.css'
import {TypePage} from "../index";
import {StoreContext} from "../../../../store.context";
import PageTitle from "../../../../common/page_title";
import HeaderActionPage from "../../../../components/header_action_page";
import Item from "./item";

interface Props {
    setType: (t: TypePage) => void
}

const DoctorInfo = ({setType}: Props) => {
    const {doctorsStore} = useContext(StoreContext)
    const [filter, setFilter] = useState<string>('')

    return (
        <div>
            <PageTitle arrow={true} onClick={() => setType('show_doctors')} title={doctorsStore.doctor.full_name}/>
            <div className={s.container}>
                <div className={s.left}>
                    <p className={s.about}>О враче</p>

                    <div className={s.item}>
                        <span>ID</span>
                        <p>{doctorsStore.doctor.id}</p>
                    </div>
                    <div className={s.item}>
                        <span>ФИО</span>
                        <p>{doctorsStore.doctor.full_name}</p>
                    </div>
                    <div className={s.item}>
                        <span>День рождения</span>
                        <p>{doctorsStore.doctor.birthday}</p>
                    </div>
                    <div className={s.item}>
                        <span>Специализация</span>
                        <p>{doctorsStore.doctor.specialization.name}</p>
                    </div>
                    <div className={s.item}>
                        <span>Пол</span>
                        <p>{doctorsStore.doctor.sex}</p>
                    </div>
                    <div className={s.item}>
                        <span>Номер телефона</span>
                        <p>{doctorsStore.doctor.phone}</p>
                    </div>
                    <div className={s.item}>
                        <span>Почта</span>
                        <p>{doctorsStore.doctor.email}</p>
                    </div>
                    <div className={s.item}>
                        <span>Примечание</span>
                        <p>{doctorsStore.doctor.description || 'Нету'}</p>
                    </div>
                </div>
                <div className={s.right}>
                    <HeaderActionPage hiddenBtns={true} setType={() => {
                    }} onChange={(e) => setFilter(e)} value={filter}/>

                    <div className={s.item_box}>
                        {doctorsStore.doctor.patients.filter(item => {
                            if (!filter) return true
                            if (item.full_name.toLowerCase().includes(filter.toLowerCase())) {
                                return true
                            }
                        }).map((el) => <Item key={el.id} {...el}/>)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DoctorInfo;