import {makeAutoObservable} from "mobx";
import {toast} from "react-toastify";

export interface SpecI {
    id: number
    name: string
}

export class SpecialStore {
    specializations: SpecI[] = []
    currentSpecialization: SpecI = null

    constructor(private SpecialService) {
        makeAutoObservable(this)
    }

    async getAllSpec() {
        try {
            const res = await this.SpecialService.getAllSpec()
            this.setAllSpec(res.data.specializations)
        } catch (e) {
            console.log(e)
        }
    }

    async getCurrentSpec(id) {
        try {
            const res = await this.SpecialService.getCurrentSpec(id)
            this.setCurrentSpec(res.data.specialization)
        } catch (e) {
            console.log(e)
        }
    }

    async addedSpec(name) {
        try {
            await this.SpecialService.addedSpec(name)
            toast.success('Специализация добавлена!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            })
        } catch (e) {
            console.log(e)
        }
    }

    async editSpec(id, name) {
        try {
            await this.SpecialService.editSpec(id, name)
            toast.success('Сохранено!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            })
        } catch (e) {
            console.log(e)
        }
    }

    setAllSpec(spec) {
        this.specializations = spec
    }

    setCurrentSpec(spec) {
        this.currentSpecialization = spec
    }
}
