import {makeAutoObservable} from "mobx";
import {toast} from "react-toastify";
import {PacientDoctorService} from "../services/doctor/pacient.service";
import {Doctor, Image} from "../admin/pacient.store";
import {ImagesService} from "../services/doctor/images.service";
import {Patient} from "./images.store";

export interface PacientI {
    id: number;
    first_name: string;
    last_name: string;
    patronymic_name: string;
    full_name: string;
    birthday: string;
}

export interface CurrentPacientI {
    id: number;
    first_name: string;
    last_name: string;
    patronymic_name: string;
    full_name: string;
    birthday: string;
    sex: string;
    phone: string;
    email: string;
    description: string;
    doctors: Doctor[];
    images: Image[];
}

export interface CurrentImagesI {
    id: number;
    url: string;
    localization: string;
    created_at: string;
    patient: Patient;
    doctor_comment: string;
    neural_network_score?: any;
    updated_at: string;
}

export class PacientDoctorStore {
    allPacient: PacientI[] | [] = []
    currentPacient: CurrentPacientI = null
    isLoading: boolean = false
    currentImage: CurrentImagesI = null

    constructor(private PacientDoctorService, private ImagesService) {
        makeAutoObservable(this)
    }

    async getPacients() {
        try {
            const res = await this.PacientDoctorService.getPacients()
            this.setAllPacient(res.data.patients)
        } catch (e) {
            console.log(e)
        }
    }

    async addedImagePacient(id, image, localization, doctor_comment, setPage, setOpenModal) {
        setOpenModal(true)
        try {
            const res = await this.PacientDoctorService.addedImagePacient(id, image, localization, doctor_comment)
            toast.success('Изображение добавлено', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            this.setCurrentImages(res.data.image)
            setPage()
            setOpenModal(false)
            this.setIsLoading(true)

        } catch (e) {
            console.log(e)
            for (let key of Object.keys(e.response.data.errors)) {
                toast.error(e.response.data.errors[key][0], {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                })
                setOpenModal(false)
            }
        }
    }

    async getCurrentPacient(id: number) {
        try {
            const res = await this.PacientDoctorService.getCurrentPacient(id)

            this.setCurrentPacient(res.data.patient)
        } catch (e) {
            console.log(e)
        }
    }

    async addedPacient(payload, setType) {

        try {
            const res = await this.PacientDoctorService.addedPacient(payload)
            setType()
            toast.success('Пациент добавлен', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        } catch (e) {
            for (let key of Object.keys(e.response.data.errors)) {
                toast.error(e.response.data.errors[key][0], {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            }
            console.log(e)
        }
    }

    async editPacient(payload, id, setType) {
        try {
            const res = await this.PacientDoctorService.editPacient(payload, id)
            setType()
            toast.success('Пациент изменен', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        } catch (e) {
            for (let key of Object.keys(e.response.data.errors)) {
                toast.error(e.response.data.errors[key][0], {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            }
            console.log(e)
        }
    }

    setAllPacient(pacients: PacientI[]) {
        this.allPacient = pacients
    }

    setCurrentPacient(pacient: CurrentPacientI) {
        this.currentPacient = pacient
    }

    setIsLoading(b: boolean) {
        this.isLoading = b
    }

    setCurrentImages(images: CurrentImagesI) {
        this.currentImage = images
    }
}
