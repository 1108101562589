import React from 'react';
import {observer} from "mobx-react-lite";
import s from './styles.module.css'
import {NavLink} from "react-router-dom";
import {ReactComponent as Arrow} from "../../../../../assets/arow_logout.svg";
import Checkbox from "../../../../../common/checkbox";
import {calculateAge} from "../../../../../utils/calc_bithday";

interface Props {
    checked: boolean
    fullName: string
    age: string
    id: number
    handleChangeStatus: (bool: boolean) => void
    onClick: () => void
}

const Item = observer(({checked, fullName, age, id, handleChangeStatus, onClick}: Props) => {
    return (
        <div className={s.container}>
            <div className={s.checkbox}>
                <Checkbox checked={checked} onChange={(bool: boolean) => handleChangeStatus(bool)}/>
            </div>

            <div className={s.content} onClick={onClick}>
                <div style={{
                    cursor: 'pointer'
                }}>
                    <h2 className={s.fullName}>{fullName}</h2>
                    <p className={s.age}>{`${calculateAge(age)} лет`}</p>
                    <p className={s.id}>{`ID:${id}`}</p>
                </div>
            </div>
        </div>
    );
});

export default Item;
