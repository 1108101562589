import React, {useContext, useEffect, useState} from 'react';
import s from './styles.module.css'
import InputMask from 'react-input-mask';
import Select from "react-select";
import {TypePage} from "../index";
import {observer} from "mobx-react-lite";
import {Doctor} from "../../../../stores/admin/doctors.store";
import {StoreContext} from "../../../../store.context";
import {ValuesI} from "../../../../stores/services/admin/doctors.service";
import PageTitle from "../../../../common/page_title";
import Input from "../../../../common/input";
import Button from "../../../../common/button";

const customStyles = {
    control: () => ({
        backgroundColor: '#2F2F2F',
        display: 'flex',
        color: '#fff',
        border: '1px solid #666666',
        borderRadius: '6px',
        height: '52px'
    }),
    singleValue: () => ({
        color: '#fff',
        position: 'absolute',
        left: '15px'
    })
}

interface Props {
    setType: (t: TypePage) => void
    type: string
}

interface EditDoctorI extends Doctor {
    specialization_id: number
    birthday: string
}

const AddedDoctor = observer(({setType, type}: Props) => {
    const {doctorsStore, specialStore} = useContext(StoreContext)

    const splitDate = doctorsStore.doctor ? doctorsStore.doctor?.birthday?.split('.') : []

    const [values, setValues] = useState<ValuesI>({
        "first_name": "",
        "last_name": "",
        "patronymic_name": "",
        "sex": "",
        "birthday": "",
        "phone": "",
        "email": "",
        "description": "",
        "specialization_id": null
    })

    const [valuesEdit, setValuesEdit] = useState<EditDoctorI>({
        ...doctorsStore.doctor,
        specialization_id: doctorsStore.doctor?.specialization?.id,
        birthday: `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`
    })

    const options = [
        {value: 'Женский', label: 'Женский'},
        {value: 'Мужской', label: 'Мужской'},
    ];

    const optionsSpec = specialStore.specializations.map((el) => ({value: el.id, label: el.name}))

    useEffect(() => {
        const getAllSpec = async () => {
            await specialStore.getAllSpec()
        }
        getAllSpec()
    }, [])

    const addedDoctor = async () => {
        const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]

        try {
            if (type === 'added_doctor') {
                const res = await doctorsStore.addedDoctor({
                    ...values,
                    birthday: `${new Date(values.birthday).getDate()}.${new Date(values.birthday).getMonth() + 1}.${new Date(values.birthday).getFullYear()}`,
                    // phone: values.phone.split('').filter(f => parseInt(f)).join('')
                    phone: values.phone.split('').filter(f => numbers.includes(+f) && f !== ' ').join('')
                }, setType)
            } else {
                const res = await doctorsStore.editDoctor({
                    ...valuesEdit,
                    // phone: valuesEdit.phone.split('').filter(f => parseInt(f)).join('')
                    phone: valuesEdit.phone.split('').filter(f => numbers.includes(+f) && f !== ' ').join('')
                }, doctorsStore.doctor.id, setType)
            }

        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div>
            <PageTitle onClick={() => setType('show_doctors')} arrow={true}
                       title={type === 'added_doctor' ? 'Создание врача' : 'Редактирование врача'}/>

            <div className={s.action_box}>
                <div className={s.inputs_box}>
                    {type === 'edit_doctor' && <div className={s.id_pac}>
                        <div className={s.id_pac_box}>
                            <p>ID Врача</p>
                            <div className={s.id_pac_content}>{`ID: ${valuesEdit.id}`}</div>
                            <br/>
                        </div>
                    </div>}


                    <Input setValue={(e) => {
                        if (type === 'added_doctor') {
                            setValues({...values, first_name: e.target.value})
                        } else {
                            setValuesEdit({...valuesEdit, first_name: e.target.value})
                        }

                    }}
                           lable={'Имя'}
                           value={type === 'added_doctor' ? values.first_name : valuesEdit.first_name}/>
                    <br/>

                    <Input setValue={(e) => {
                        if (type === 'added_doctor') {
                            setValues({...values, last_name: e.target.value})
                        } else {
                            setValuesEdit({...valuesEdit, last_name: e.target.value})
                        }
                    }} lable={'Фамилия'}
                           value={type === 'added_doctor' ? values.last_name : valuesEdit.last_name}
                    />
                    <br/>

                    <Input setValue={(e) => {
                        if (type === 'added_doctor') {
                            setValues({...values, patronymic_name: e.target.value})
                        } else {
                            setValuesEdit({...valuesEdit, patronymic_name: e.target.value})
                        }
                    }} lable={'Отчество'}
                           value={type === 'added_doctor' ? values.patronymic_name : valuesEdit.patronymic_name}
                    />
                    <br/>

                    <div className={s.sex}>
                        <p>Пол</p>
                        <Select
                            //@ts-ignore
                            options={options}
                            defaultValue={type === 'added_doctor' ? values.sex : valuesEdit.sex}
                            //@ts-ignore
                            styles={customStyles}
                            placeholder={type !== 'added_doctor' ? valuesEdit.sex : 'Не выбрано'}
                            onChange={(e) => {
                                //@ts-ignore
                                setValues({...values, "sex": e.value})
                            }}
                        />
                    </div>

                    <br/>

                    <Input setValue={(e) => {
                        if (type === 'added_doctor') {
                            setValues({...values, birthday: e.target.value})
                        } else {
                            setValuesEdit({...valuesEdit, birthday: e.target.value})
                        }
                    }} lable={'Дата рождения'}
                           type={'date'}
                           value={type === 'added_doctor' ? values.birthday : valuesEdit.birthday}
                    />
                    <br/>

                    <div className={s.number_box}>
                        <p>Телефон</p>
                        <InputMask onChange={(e) => {
                            if (type === 'added_doctor') {
                                setValues({...values, phone: e.target.value})
                            } else {
                                setValuesEdit({...valuesEdit, phone: e.target.value})
                            }
                        }} mask={"9 (999) 999-99-99"}
                                   value={type === 'added_doctor' ? values.phone : valuesEdit.phone}/>
                    </div>

                    <br/>

                    <Input setValue={(e) => {
                        if (type === 'added_doctor') {
                            setValues({...values, email: e.target.value})
                        } else {
                            setValuesEdit({...valuesEdit, email: e.target.value})
                        }

                    }}
                           lable={'Почта'}
                           type={'email'}
                           value={type === 'added_doctor' ? values.email : valuesEdit.email}/>


                    {type !== 'edit_doctor' && <div className={s.sex}>
                        <br/>
                        <p>Специальность</p>
                        <Select
                            //@ts-ignore
                            options={optionsSpec}
                            defaultValue={values.specialization_id}
                            //@ts-ignore
                            styles={customStyles}
                            placeholder={'Не выбрано'}
                            onChange={(e) => {
                                //@ts-ignore
                                setValues({...values, "specialization_id": e.value})
                            }}
                        />
                    </div>}
                </div>
                <div className={s.right}>
                    {type === 'edit_doctor' && <div className={s.sex}>
                        <p>Специальность</p>
                        <Select
                            //@ts-ignore
                            options={optionsSpec}
                            defaultValue={valuesEdit.specialization_id}
                            //@ts-ignore
                            styles={customStyles}
                            placeholder={optionsSpec.find(f => f.value === valuesEdit.specialization_id)?.label}
                            onChange={(e) => {
                                //@ts-ignore
                                setValues({...values, "specialization_id": e.value})
                            }}
                        />
                        <br/>
                    </div>}
                    <div className={s.description_box}>
                        <p className={s.sex}>Примечание</p>
                        <textarea value={type === 'added_doctor' ? values.description : valuesEdit.description}
                                  onChange={(e) => {
                                      if (type === 'added_doctor') {
                                          setValues({...values, description: e.target.value})
                                      } else {
                                          setValuesEdit({...valuesEdit, description: e.target.value})
                                      }
                                  }}
                                  className={s.description}/>
                    </div>
                </div>
            </div>

            <div className={s.btn_box}>
                <Button onClick={addedDoctor} type={'red'} title={type === 'added_doctor' ? 'Создать' : 'Сохранить'}/>
                <Button onClick={() => setType('show_doctors')} type={'gray'} title={'Отменить'} className={s.remove}/>
            </div>
        </div>
    );
});

export default AddedDoctor;
