import React, {useContext, useState} from 'react';
import s from './styles.module.css'
import {TypePage} from "../index";
import PageTitle from "../../../../common/page_title";
import Input from "../../../../common/input";
import Button from "../../../../common/button";
import {observer} from "mobx-react-lite";
import {StoreContext} from "../../../../store.context";

interface Props {
    setType: (t: TypePage) => void
}

const AddedSpecialization = observer(({setType}: Props) => {
    const {specialStore} = useContext(StoreContext)

    const [value, setValue] = useState<string>('')

    return (
        <div>
            <PageTitle arrow={true} onClick={() => setType('all_specialization')}
                       title={"Создание специализации"}/>

            <div className={s.added_box}>
                <Input lable={'Название'} value={value} setValue={(e) => setValue(e.target.value)}/>
                <div className={s.btn_box}>
                    <Button onClick={async () => {
                        await specialStore.addedSpec(value)
                        setType('all_specialization')
                    }} title={'Создать'} type={'red'}/>
                    <Button className={s.gray} onClick={() => setType('all_specialization')} title={'Отменить'}
                            type={'gray'}/>
                </div>
            </div>
        </div>
    );
});

export default AddedSpecialization;