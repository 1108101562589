import {makeAutoObservable} from "mobx";

export interface PacientI {
    id: number;
    first_name: string;
    last_name: string;
    patronymic_name: string;
    full_name: string;
    birthday: string;
}

export interface Specialization {
    id: number;
    name: string;
}

export interface Doctor {
    id: number;
    first_name: string;
    last_name: string;
    patronymic_name: string;
    full_name: string;
    birthday: string;
    specialization: Specialization;
}

export interface Image {
    id: number;
    url: string;
    localization: string;
    created_at: string;
}

export interface RootObject {
    id: number;
    first_name: string;
    last_name: string;
    patronymic_name: string;
    full_name: string;
    birthday: string;
    sex: string;
    phone: string;
    email: string;
    description: string;
    doctors: Doctor[];
    images: Image[];
}

export class PacientStore {
    allPacient: PacientI[] | [] = []
    pacient: RootObject = null
    currentInfo: Image = null

    constructor(private PacientService) {
        makeAutoObservable(this)
    }

    async getPacients() {
        try {
            const res = await this.PacientService.getAllPacient()
            this.setAllPacient(res.data.patients)
        } catch (e) {
            console.log(e)
        }
    }

    async getPacient(id) {
        try {
            const res = await this.PacientService.getPacient(id)
            this.setPacient(res.data.patient)
        } catch (e) {
            console.log(e)
        }
    }

    setAllPacient(pacients) {
        this.allPacient = pacients
    }

    setPacient(pacient) {
        this.pacient = pacient
    }

    getDeepInfoPacient(id) {
        this.currentInfo = this.pacient.images.find(f => f.id === id)
    }
}
