import axiosConfig from "../../../api";

interface ILogin {
    name: string,
    password: string
}

export class AuthDoctorService {
    async login(loginRequest: ILogin) {
        const res = await axiosConfig().post('/api/doctor/auth/login', {
            email: loginRequest.name,
            password: loginRequest.password
        })
        return res
    }

    async logout() {
        const res = await axiosConfig().delete('/api/doctor/auth/logout', {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        })
        return res
    }
}
