import React, {ChangeEvent} from 'react';
import s from './styles.module.css'

interface Props {
    lable: string,
    value: string,
    setValue: (event: ChangeEvent<HTMLInputElement>) => void
    type?: string
}

const Input = ({lable, value, setValue, type = 'text'}: Props) => {
    return (
        <div className={s.input_box}>
            <p>{lable}</p>
            <input type={type} onChange={setValue} value={value}/>
        </div>
    );
};

export default Input;
