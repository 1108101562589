import React, {useContext, useEffect, useState} from 'react';
import s from './style.module.css';
import {StoreContext} from "../../store.context";
import LogoTitle from "../../common/logo_title";
import {ToastContainer} from "react-toastify";
import {useNavigate} from "react-router-dom";
import classNames from "classnames";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import Select from "react-select";
import Input from "../../common/input";

interface ValuesI {
    name: string,
    password: string
}

const customStyles = {
    control: () => ({
        backgroundColor: '#2F2F2F',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        border: '1px solid #666666',
        borderRadius: '6px',
        height: '52px',
        width: '100%'
    }),
    singleValue: () => ({
        color: '#fff',
        position: 'absolute',
        left: '15px'
    })
}

const Auth = () => {
    const {authStore, authDoctorStore} = useContext(StoreContext)

    const navigate = useNavigate()

    const [values, setValues] = useState<ValuesI>({
        // name: 'gromvolk97@gmail.com',
        name: '',
        // name: 'admin',
        // password: 'Lk*pIsD23VaMqwW'
        // password: 'aDSfaibm'
        password: ''
    })

    const [role, setRole] = useLocalStorage('role', 'admin')

    const options = [
        {value: 'admin', label: 'Админ'},
        {value: 'doctor', label: 'Врач'},
    ];
    return (
        <div className={s.container}>
            <div className={s.box}>
                <LogoTitle/>

                <div className={s.input_box}>
                    <Input value={values.name} setValue={(e) => setValues({...values, name: e.target.value})}
                           lable="Почта"/>
                    <br/>
                    <Input value={values.password} setValue={(e) => setValues({...values, password: e.target.value})}
                           lable={'Пароль'}/>
                    <div className={s.select}>
                        <p className={s.role_title}>Роль</p>
                        <Select
                            //@ts-ignore
                            options={options}
                            defaultValue={options.find(f => f.value === role)?.label}
                            placeholder={options.find(f => f.value === role)?.label}
                            //@ts-ignore
                            styles={customStyles}
                            onChange={(e) => {
                                //@ts-ignore
                                setRole(e.value)
                            }}
                        />
                    </div>
                </div>
                <div className={classNames(s.btn, (!role || role === null) && s.disable)} onClick={async () => {
                    if (!role || role === null) return
                    if (role === 'admin') {
                        await authStore.login({...values}, navigate)
                        setRole(role)
                    }
                    if (role === 'doctor') {
                        await authDoctorStore.login({...values}, navigate)
                        setRole(role)
                    }

                }}>Войти
                </div>
            </div>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    );
};

export default Auth;
